import { IconProps } from '../../types';

const WindowIcon = ({ title = 'Video', className }: IconProps) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 9H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 13H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 17H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 5.99995C4.99999 5.44767 5.44768 5 5.99995 5C6.55221 5 6.99991 5.44767 6.9999 5.99995C6.99989 6.55218 6.5522 6.9999 5.99995 6.9999C5.4477 6.9999 5.00001 6.55218 5 5.99995Z" fill="currentColor" />
        <path d="M8 5.99995C7.99999 5.44767 8.44768 5 8.99995 5C9.55221 5 9.99991 5.44767 9.9999 5.99995C9.99989 6.55218 9.5522 6.9999 8.99995 6.9999C8.4477 6.9999 8.00001 6.55218 8 5.99995Z" fill="currentColor" />
        <path d="M11 5.99995C11 5.44767 11.4477 5 11.9999 5C12.5522 5 12.9999 5.44767 12.9999 5.99995C12.9999 6.55218 12.5522 6.9999 11.9999 6.9999C11.4477 6.9999 11 6.55218 11 5.99995Z" fill="currentColor" />
    </svg>
);

export default WindowIcon;
