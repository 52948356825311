import {
    ComponentType, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from '@rainbow-modules/validation';
import { RenderIf } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Footer from 'components/Footer';
import Input from 'components/Input';
import LoadingShape from 'components/LoadingShape';
import SearchIcon from 'components/icons/search';
import useResponders from 'data/firestore/agent/llm/useCollection';
import useWebsiteChatbots from 'data/firestore/agent/widgetchatbot/useCollection';
import useHttpQuery from 'data/firestore/useHttpQuery';
import useResponderMapping from 'data/firestore/agent/respondermapping/useCollection';
import useRedirectWhenNotAdmin from 'hooks/useRedirectWhenNotAdmin';
import CreateEditResponderForm from 'pages/LLMResponders/CreateResponderForm';
import { NewResponderFormHeader } from 'pages/LLMResponders/RespondersList';
import { ADD_EDIT_LLM_RESPONDER_MODAL } from '../../constants';
import {
    Container,
    LeftColumn,
    Main,
    SideBar,
    Option,
    OptionIconContainer,
    Modal,
    GroupIcon,
} from './styled';
import { Provider } from './context';
import { Feature, FeatureGroup, FeaturesListProps } from './types';
import MetaDMSkills from './MetaDMSkills';
import OpportunitiesSkills from './OpportunitiesSkils';
import CallSkills from './CallSkills';
import WidgetChatbots from './WidgetChatbots';

const NullComponent = () => null;

const chatbotIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z" stroke="#393841" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 9H20" stroke="#393841" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 13H17" stroke="#393841" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 17H17" stroke="#393841" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 5.99995C4.99999 5.44767 5.44768 5 5.99995 5C6.55221 5 6.99991 5.44767 6.9999 5.99995C6.99989 6.55218 6.5522 6.9999 5.99995 6.9999C5.4477 6.9999 5.00001 6.55218 5 5.99995Z" fill="#393841"/>
<path d="M8 5.99995C7.99999 5.44767 8.44768 5 8.99995 5C9.55221 5 9.99991 5.44767 9.9999 5.99995C9.99989 6.55218 9.5522 6.9999 8.99995 6.9999C8.4477 6.9999 8.00001 6.55218 8 5.99995Z" fill="#393841"/>
<path d="M11 5.99995C11 5.44767 11.4477 5 11.9999 5C12.5522 5 12.9999 5.44767 12.9999 5.99995C12.9999 6.55218 12.5522 6.9999 11.9999 6.9999C11.4477 6.9999 11 6.55218 11 5.99995Z" fill="#393841"/>
</svg>
`;

const widgetsGroup = {
    name: 'website_chatbots',
    label: 'Website Chatbot',
    description: 'Create Chatbots for your websites to engage more with your customers.',
    icon: chatbotIcon,
};

const skillGroupComponentMap: Record<string, ComponentType<FeaturesListProps>> = {
    meta_dms: MetaDMSkills,
    opportunities: OpportunitiesSkills,
    calls: CallSkills,
    website_chatbots: WidgetChatbots,
};

const Skills = () => {
    const { agentId = '' } = useParams();
    const [selectedGroup, selectGroup] = useState<FeatureGroup>();
    const [searchQuery, setSearchQuery] = useState<string>('');

    const connectedAddModifyProps = useConnectModal(
        ADD_EDIT_LLM_RESPONDER_MODAL,
        { size: 'small' },
    );
    const [, closeModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_MODAL);
    const {
        initialValues,
        onSuccess,
        responderData = {},
        ...modalProps
    } = connectedAddModifyProps;

    useRedirectWhenNotAdmin({});

    const {
        data: { groups, features } = { groups: [], features: [] },
        isLoading: isLoadingSkills,
    } = useHttpQuery<{ groups: FeatureGroup[], features: Feature[] }>({
        pathname: `/agents/${agentId}/skills`,
        queryOptions: {
            enabled: Boolean(agentId),
        },
    });

    const { data: responders, isLoading: isLoadingResponders } = useResponders(agentId);

    const {
        data: websiteChatbots,
        isLoading: isLoadingWebsiteChatbots,
    } = useWebsiteChatbots(agentId);

    const {
        data: responderMapping,
        isLoading: isLoadingResponderMapping,
    } = useResponderMapping(agentId);

    const isLoading = useMemo(
        () => isLoadingResponders
            || isLoadingResponderMapping
            || isLoadingSkills
            || isLoadingWebsiteChatbots,
        [isLoadingResponders, isLoadingResponderMapping, isLoadingSkills, isLoadingWebsiteChatbots],
    );

    const allFeatures = useMemo(() => [
        ...features,
        ...websiteChatbots.map((chatbot) => ({
            name: chatbot.featureName,
            label: chatbot.name,
            icon: chatbotIcon,
            group: 'website_chatbots',
            data: chatbot,
            url: chatbot.url,
            featureSchema: chatbot.featureSchema,
        })),
    ], [features, websiteChatbots]);

    const allGroups = useMemo(() => [...groups, widgetsGroup], [groups]);

    const filteredFeatures = useMemo(() => (
        isEmpty(searchQuery)
            ? allFeatures
            : allFeatures.filter(
                (feature) => new RegExp(searchQuery, 'gi').test(feature.label),
            )
    ), [allFeatures, searchQuery]);

    const visibleGroups = useMemo(
        () => {
            if (isEmpty(searchQuery)) return allGroups;
            const filteredGroups = filteredFeatures.reduce(
                (result: string[], feature) => {
                    if (result.includes(feature.group)) return result;
                    return [...result, feature.group];
                },
                [],
            );

            return allGroups.filter((group) => filteredGroups.includes(group.name));
        },
        [searchQuery, allGroups, filteredFeatures],
    );

    const featuresInGroup = useMemo(
        () => filteredFeatures
            .filter((feature) => feature.group === selectedGroup?.name)
            .map((feature) => ({
                ...feature,
                mapping: responderMapping?.find(
                    (responderMap) => responderMap.feature === feature.name,
                ),
            })),
        [filteredFeatures, selectedGroup, responderMapping],
    );

    const groupsList = useMemo(
        () => visibleGroups
            .map((group) => (
                <Option
                    key={group.name}
                    selected={group.name === selectedGroup?.name}
                    onClick={() => selectGroup(group)}
                >
                    <OptionIconContainer>
                        <GroupIcon svgStr={group.icon} />
                    </OptionIconContainer>
                    {group.label}
                </Option>
            )),
        [selectedGroup, visibleGroups],
    );

    useEffect(
        () => {
            if (
                !selectedGroup?.name
                || !visibleGroups.find((group) => group.name.includes(selectedGroup.name))
            ) {
                selectGroup(visibleGroups.at(0));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [visibleGroups],
    );

    // eslint-disable-next-line max-len
    const SkillsListComponent = skillGroupComponentMap[selectedGroup?.name as string] || NullComponent;

    return (
        <Provider value={{ responders, responderMapping }}>
            <Container>
                <LeftColumn>
                    <Input
                        icon={<SearchIcon />}
                        placeholder="Search in AI Skills"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <SideBar>
                        <RenderIf isTrue={isLoadingSkills}>
                            <LoadingShape />
                            <LoadingShape />
                            <LoadingShape />
                        </RenderIf>
                        {groupsList}
                    </SideBar>
                </LeftColumn>
                <Main>
                    <SkillsListComponent
                        agentId={agentId}
                        group={selectedGroup}
                        features={featuresInGroup as Feature[]}
                        isLoading={isLoading}
                    />
                </Main>
            </Container>
            <Footer />
            <Modal
                {...modalProps}
                title={<NewResponderFormHeader />}
            >
                <CreateEditResponderForm
                    agentId={agentId}
                    initialValues={initialValues}
                    disabledFields={['skill', 'feature']}
                    hiddenFields={['skill']}
                    onRequestClose={closeModal}
                    onSuccess={onSuccess}
                    {...(responderData as object)}
                />
            </Modal>
        </Provider>
    );
};

export default Skills;
