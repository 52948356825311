import { useState, useEffect } from "react";
import { FirestoreError } from "firebase/firestore";
import onSnapshotCollection from "./onSnapshotCollection";
import { Tag } from "./types";
import {
  EntityGet,
  HookCollectionOpts,
  HookReturnCollection,
} from "../../types";

export type TagUseCollectionHook<T> = (
  agentId: string,
  opts?: HookCollectionOpts<T>
) => HookReturnCollection<T>;

const useCollection: TagUseCollectionHook<EntityGet<Tag>> = (
  agentId,
  opts = {}
) => {
  const [data, setData] = useState<Array<EntityGet<Tag>>>([]);
  const [error, setError] = useState<FirestoreError | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { disabled = false, onSnap, track = [] } = opts;
  useEffect(() => {
    if (!disabled) {
      setLoading(true);
      const unsub = onSnapshotCollection(
        agentId,
        opts || {},
        (docs) => {
          setData(docs);
          setLoading(false);
          if (typeof onSnap === "function") {
            onSnap(docs);
          }
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
      return () => unsub();
    }
  }, [disabled, ...track]);
  return { data, error, isLoading };
};

export default useCollection;
