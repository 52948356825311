import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useOpenModal } from '@rainbow-modules/hooks';
import Button from 'components/Button';
import Plus from 'components/icons/plus';
import SearchIcon from 'components/icons/search';
import useSearchFilters from 'hooks/useSearchFilters';
import { ADD_EDIT_WORKPLACE_MODAL } from '../../../constants';
import { StyledCard } from '../styled';
import {
    RightContent, SearchContainer, SearchInput, StyledBadgeOverlay,
} from './styled';
import WorkplacesList from './workplacesList';
import FilterButton from './FilterButton';

const dataSearchFilters = ['search', 'createdAt', 'tags'];

const WorkplacesListPage = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigate();
    const [openDrawer] = useOpenModal(ADD_EDIT_WORKPLACE_MODAL);

    const {
        values: filterValues,
        getValue: getFilterValue,
        setValue: setFilterValue,
    } = useSearchFilters({
        filterNames: dataSearchFilters,
    });

    const activeFiltersCount = useMemo(
        () => dataSearchFilters.reduce(
            (count, key) => {
                if (key === 'search') return count;
                return (
                    getFilterValue(key) !== null
                        ? count + 1
                        : count
                );
            },
            0,
        ),
        [getFilterValue],
    );

    return (
        <StyledCard>
            <SearchContainer>
                <SearchInput
                    type="search"
                    placeholder="Search workplace..."
                    variant="bare"
                    icon={<SearchIcon />}
                    borderRadius="semi-rounded"
                    onChange={(e) => setFilterValue('search', e.target.value)}
                    value={getFilterValue('search') || ''}
                />
                <RightContent>
                    <StyledBadgeOverlay
                        value={activeFiltersCount}
                        isHidden={activeFiltersCount === 0}
                        variant="brand"
                        position="top-left"
                    >
                        <FilterButton
                            label="Filters"
                            agentId={agentId}
                        />
                    </StyledBadgeOverlay>
                    <Button
                        variant="brand"
                        size="medium"
                        borderRadius="semi-rounded"
                        onClick={() => openDrawer({
                            header: 'Add a new workplace',
                            onCompleted: (
                                workplace: Record<string, unknown>,
                            ) => navigate(workplace.id as string),
                        })}
                    >
                        <Plus className="rainbow-m-right_small" />
                        New
                    </Button>
                </RightContent>
            </SearchContainer>
            <WorkplacesList filterValues={filterValues} />
        </StyledCard>
    );
};

export default WorkplacesListPage;
