import styled from 'styled-components';
import SendNoteForm from 'components/SendNoteForm';
import { WHITE } from '../../../../constants';

export const NotesContainer = styled.div`
    background: ${WHITE};
    width: 100%;
    padding: 0 1rem 1.75rem 1rem;
    display: flex;
    position: absolute;
    bottom: 2px;
    z-index: 1001;
`;

export const StyledSendNoteForm = styled(SendNoteForm)`
    flex-grow: 1;
    z-index: 1;
    margin: 0;
`;

export const Gradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 90%);
    height: 6rem;
    position: absolute;
    top: -6rem;
    left: 0;
    width: calc(100% - 1rem);
    z-index: 1000;
`;
