import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import usePipeline from 'data/firestore/agent/pipeline/use';
import usePipelineStages from 'data/firestore/agent/pipeline/stage/useCollection';
import useOpportunity from 'data/firestore/agent/opportunity/use';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import useSearchFilters from 'hooks/useSearchFilters';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import useHttpQuery from 'data/firestore/useHttpQuery';
import { OPPORTUNITY_DETAILS_DRAWER } from '../../constants';
import OpportunityDetails from './opportunityDetails';
import { DrawerGradient, StyledDrawer } from './styled';

const OpportunityDetailsDrawer = () => {
    const { agentId = '' } = useParams();
    const {
        getValue,
        setValue,
        clear,
    } = useSearchFilters({
        filterNames: ['showOpportunity'],
    });

    const {
        data: opportunityData = {}, isOpen, ...connectedDrawerProps
    } = useConnectModal(OPPORTUNITY_DETAILS_DRAWER);

    const [openDrawer, closeDrawer] = useOpenModal(OPPORTUNITY_DETAILS_DRAWER);

    const opportunityId = getValue('showOpportunity') as string;

    const shouldFetchOpportunity = useMemo(
        () => Boolean(opportunityData && typeof opportunityData === 'string' && agentId),
        [agentId, opportunityData],
    );

    const { data: opportunityInfo, isLoading: isLoadingOpportunity } = useOpportunity(
        agentId,
        opportunityId,
        {
            disabled: !shouldFetchOpportunity || !opportunityId,
            track: [agentId, opportunityId],
        },
    );

    const pipelineId = useMemo(
        () => ((
            typeof opportunityData === 'string'
                ? opportunityInfo
                : opportunityData
        ) as EntityGet<Opportunity>
        ).pipelineId || '',
        [opportunityData, opportunityInfo],
    );

    const {
        data: pipeline,
        isLoading: isLoadingPipeline,
    } = usePipeline(
        agentId,
        pipelineId,
        {
            disabled: !agentId || !pipelineId,
            track: [agentId, pipelineId],
        },
    );

    const shouldFetchPipelineStages = useMemo(
        () => agentId && pipelineId,
        [agentId, pipelineId],
    );

    const {
        data: stages = [],
        isLoading: isLoadingPipelineStages,
    } = usePipelineStages(
        agentId,
        pipelineId,
        {
            disabled: !shouldFetchPipelineStages,
            track: [agentId, pipelineId],
        },
    );

    const {
        data: opportunitySummary,
        isLoading: isLoadingSummary,
        isFetching: isFetchingSummary,
    } = useHttpQuery<{ summary: string, updatedAt: string }>({
        method: 'POST',
        pathname: `/agents/${agentId}/opportunities/${opportunityId}/summary`,
        queryOptions: {
            enabled: isOpen && !!agentId && !!opportunityId,
            keepPreviousData: false,
            retry: 1,
        },
    });

    const orderedPipelineStages = useMemo(
        () => {
            const pipelineStageOrder = pipeline?.stages || [];

            return pipelineStageOrder.reduce(
                (activeStages: EntityGet<Stage>[], stageId: string) => {
                    const found = stages.find((stage) => stage.id === stageId);
                    if (found) {
                        return [...activeStages, found];
                    }

                    return activeStages;
                },
                [],
            );
        },
        [pipeline?.stages, stages],
    );

    const opportunity = useMemo(
        () => (
            typeof opportunityData === 'string'
                ? opportunityInfo
                : opportunityData
        ) as EntityGet<Opportunity>,
        [opportunityData, opportunityInfo],
    );

    const handleCloseDrawer = useCallback(
        () => {
            if (isOpen) {
                clear();
                closeDrawer();
            }
        },
        [closeDrawer, isOpen, clear],
    );

    useEffect(() => {
        if (isOpen) {
            const id = (
                typeof opportunityData === 'string'
                    ? opportunityData
                    : (opportunityData as Opportunity)?.id
            );
            if (id) {
                setValue('showOpportunity', id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (opportunityId && !isOpen) {
            openDrawer({ data: opportunityId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opportunityId]);

    const isLoading = isLoadingOpportunity
        || isLoadingPipeline
        || isLoadingPipelineStages
        || isLoadingSummary
        || isFetchingSummary;

    return (
        <StyledDrawer
            {...connectedDrawerProps}
            isOpen={isOpen}
            onRequestClose={handleCloseDrawer}
            hideCloseButton
            slideFrom="right"
            size="medium"
        >
            <DrawerGradient />
            <RenderIf isTrue={isOpen}>
                <OpportunityDetails
                    onRequestClose={handleCloseDrawer}
                    agentId={agentId}
                    pipelineStages={orderedPipelineStages}
                    data={opportunity}
                    isLoadingSummary={isLoading}
                    summary={opportunitySummary}
                />
            </RenderIf>
        </StyledDrawer>
    );
};

export default OpportunityDetailsDrawer;
