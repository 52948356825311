import { useMemo } from 'react';
import { Column } from 'react-rainbow-components';
import { useConnectModal } from '@rainbow-modules/hooks';
import DateTimeColumn from 'components/DateTimeColumn';
import FormModal from 'components/FormModal';
import Table from 'components/Table';
import useAgentData from 'hooks/useAgentData';
import useUserRoles from 'hooks/useUserRoles';
import LanguageColumn from 'pages/Customers/CustomersList/columns/preferredLanguage';
import ActionsColumn from './columns/actions';
import { CellWrapper } from '../columns/styled';
import { EDIT_PHONE_LANGUAGE } from '../../../constants';
import Fields from './fields';
import PhoneColumn from '../columns/phoneNumber';
import {
    StyledCard, EmptyLabel, TableContainer, Header, Title, Description,
    HeaderContent,
} from '../styled';

const AgentPhoneNumbers = () => {
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const { role, isAdmin } = useUserRoles();
    const userCanWrite = useMemo(() => isAdmin || ['owner', 'editor'].includes(role as string), [role, isAdmin]);
    const connectedModalProps = useConnectModal(EDIT_PHONE_LANGUAGE);
    return (
        <>
            <StyledCard>
                <Header>
                    <HeaderContent>
                        <Title>Sara AI Phone Numbers</Title>
                        <Description>
                            These numbers are used for Sara AI to handle incoming calls
                        </Description>
                    </HeaderContent>
                </Header>
                <TableContainer>
                    <Table
                        data={agent?.phoneNumbers}
                        keyField="id"
                        isLoading={isLoadingAgent}
                    >
                        <Column
                            header="Phone number"
                            field="number"
                            component={PhoneColumn}
                        />
                        <Column
                            header="Preferred Language"
                            field="language"
                            component={LanguageColumn}
                        />
                        <Column
                            header="Created At"
                            field="createdAt"
                            component={DateTimeColumn}
                            emptyLabel={(
                                <CellWrapper>
                                    <EmptyLabel>-</EmptyLabel>
                                </CellWrapper>
                            )}
                        />
                        {userCanWrite && (
                            <Column
                                header="Actions"
                                component={ActionsColumn}
                                disabled={!userCanWrite}
                                defaultWidth={100}
                            />
                        )}
                    </Table>
                </TableContainer>
            </StyledCard>
            <FormModal
                borderRadius="semi-square"
                size="small"
                fields={Fields}
                {...connectedModalProps}
            />
        </>
    );
};

export default AgentPhoneNumbers;
