import { Option } from 'react-rainbow-components';
import { Tag } from 'data/firestore/agent/tag/types';

const Options = ({ tags = [] }: { tags: Tag[] }) => tags.map(
    (tag) => ((
        <Option
            key={`entity-tag__${tag.id}`}
            label={tag.name}
            name={tag.id}
            value={tag.name}
            searchableText={tag.name}
        />

    )),
);

export default Options;
