import {
    MouseEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-rainbow-components';
import useWorkplaces from 'data/firestore/agent/workplace/useCollectionWithPagination';
import { orderBy, query } from 'firebase/firestore';
import { EntityGet } from 'data/firestore/types';
import { Workplace } from 'data/firestore/agent/workplace/types';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import Pagination from 'components/Pagination';
import DateTimeColumn from 'components/DateTimeColumn';
import Table from 'components/Table';
import { ALGOLIA_INDEX_WORKPLACES, PAGE_SIZE } from '../../../constants';
import {
    Container, EntriesText, PaginationContainer,
} from './styled';
import UserCreatedByColumn from './columns/createdBy';
import WorkplaceColumn from './columns/workplace';
import ActionsColumn from './columns/actions';
import TagsColumn from './columns/tags';
import buildAlgoliaFilters from './helpers/buildAlgoliaFilters';

interface WorkplacesListProps {
    filterValues: Record<string, string | string[] | null>;
}

const WorkplacesList = ({ filterValues }: WorkplacesListProps) => {
    const [activePage, setActivePage] = useState(1);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const filters = useMemo(() => Object.values(filterValues).filter((value) => !!value).join('|'), [filterValues]);

    const { agentId = '' } = useParams<{ agentId: string }>();
    const {
        data: workplaces,
        isLoading: isLoadingWorkplaces,
        totalRecords: totalWorkplaces,
        nextPage,
        prevPage,
        error,
    } = useWorkplaces(
        agentId,
        {
            disabled: !agentId,
            limit: PAGE_SIZE,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', sortDirection)),
            track: [agentId, sortDirection],
        },
    );

    useEffect(() => setActivePage(1), [filters]);

    const algoliaData = useAlgoliaSearchData<EntityGet<Workplace>>({
        search: (filterValues.search as string) ?? '',
        activePage,
        pageSize: PAGE_SIZE,
        indexName: ALGOLIA_INDEX_WORKPLACES,
        filters: buildAlgoliaFilters({ filters: filterValues, agentId }),
        enabled: Boolean(filters),
    });

    const disableSorting = useMemo(
        () => Boolean(algoliaData?.hits),
        [algoliaData?.hits],
    );

    const dataToShow = algoliaData?.hits || workplaces;
    const totalHits = typeof algoliaData?.nbHits === 'number' ? algoliaData.nbHits : totalWorkplaces;
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoadingWorkplaces) return;
        if (page === activePage) return;
        if (!algoliaData?.hits) {
            if (page > activePage) nextPage();
            if (page < activePage) prevPage();
        }
        setActivePage(page);
    };

    const handleOnSort = useCallback(
        (
            event: MouseEvent<HTMLElement>,
            field: string,
            nextSortDirection: 'asc' | 'desc',
        ) => {
            if (disableSorting) return;
            setSortDirection(nextSortDirection);
        },
        [disableSorting],
    );

    if (error || (!workplaces && !isLoadingWorkplaces)) {
        return (
            <Container>
                <h1>Something went wrong</h1>
                {error?.message && (
                    <p>
                        {error.name}
                        :
                        {' '}
                        {error.code}
                    </p>
                )}
            </Container>
        );
    }

    return (
        <Container>
            <Table
                data={dataToShow}
                keyField="id"
                isLoading={isLoadingWorkplaces}
                sortedBy="createdAt"
                onSort={handleOnSort}
                sortDirection={sortDirection}
            >
                <Column
                    header="Workplace"
                    field="id"
                    component={WorkplaceColumn}
                />
                <Column
                    header="Tags"
                    field="tags"
                    component={TagsColumn}
                />
                <Column
                    header="Created At"
                    field="createdAt"
                    component={DateTimeColumn}
                    sortable={!disableSorting}
                    width={200}
                />
                <Column
                    header="Created By"
                    field="createdBy"
                    component={UserCreatedByColumn}
                    width={300}
                    sortable={!disableSorting}
                />
                <Column
                    field="id"
                    component={ActionsColumn}
                    width={100}
                    agentId={agentId}
                />
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalHits}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={activePage}
                    pages={pages}
                    onChange={handlePageChange}
                />
            </PaginationContainer>
        </Container>
    );
};

export default WorkplacesList;
