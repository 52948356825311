import { useMemo } from 'react';
import CalendarIcon from 'components/icons/calendar2';
import PhoneIcon from 'components/icons/phone2';
import ClockIcon from 'components/icons/clock2';
import DoubleCheckIcon from 'components/icons/doubleCheck';
import SummaryItem from 'components/SummaryItem';
import ChartContainer from 'components/InsightSectionContainer';
import { useParams } from 'react-router-dom';
import { getFormatter } from 'data/services/date/formatter';
import { Dateinsight } from 'data/firestore/agent/dateinsight/types';
import Footer from 'components/Footer';
import { DateTime } from 'luxon';
import {
    Container, SumaryContainer, StyledPlot, HeatmapContainer,
} from './styled';
import useLast30DaysCalls from './getLast30Days';
import transformLabelDateFormat from './helpers/getLabelFromDate';

function formatDuration(duration: number) {
    const seconds = Math.floor(duration % 60);
    const minutes = Math.floor((duration / 60) % 60);
    const hours = Math.floor((duration / (60 * 60)) % 24);

    let result = `${seconds}s`;
    if (minutes) result = `${minutes}m ${result}`;
    if (hours) result = `${hours}h ${result}`;
    return result;
}
const dateFormatterShort = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

type Hour = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 |
11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 |
21 | 22 | 23;

function calculatePeakHour(data: Dateinsight[]): Hour | undefined {
    let peakHour: Hour = 0;
    let maxCalls = 0;

    for (let hour: number = 0; hour < 24; hour += 1) {
        const totalCalls = data.reduce(
            (sum, day) => sum + (day?.byHour?.[hour as Hour]?.total || 0),
            0,
        );
        if (totalCalls > maxCalls) {
            maxCalls = totalCalls;
            peakHour = hour as Hour;
        }
    }

    return maxCalls ? peakHour : undefined;
}

function calculatePeakData(data: Dateinsight[], range: Hour[]): number[][] {
    return range.map((hour) => data.map((day) => day?.byHour?.[hour]?.total || 0));
}

const getSummary = (data: Dateinsight[]) => {
    const totalCalls = data.reduce((acc, item) => acc + item.total, 0);
    const totalSeconds = data.reduce((acc, item) => acc + item.duration, 0);
    const callsPerDayAvg = totalCalls / data.length;
    const secondsPerDayAvg = totalSeconds / data.length;
    const callDurationAvg = totalSeconds / (totalCalls || 1);
    const peakHour = calculatePeakHour(data) as Hour;

    return {
        totalCalls,
        totalSeconds,
        callsPerDayAvg: Math.round(callsPerDayAvg),
        secondsPerDayAvg: Math.round(secondsPerDayAvg),
        callDurationAvg: Math.round(callDurationAvg),
        peakHour,
    };
};

const formatPeakHour = (hour?: Hour) => (hour !== undefined ? `${hour}h - ${hour + 1}h` : 'No data');

const Insights = () => {
    const { agentId } = useParams();

    const { data, isLoading } = useLast30DaysCalls(agentId as string);
    const { insights = [] } = data;
    const {
        totalCalls,
        callsPerDayAvg,
        totalSeconds,
        secondsPerDayAvg,
        callDurationAvg,
        peakHour,
    } = getSummary(insights);
    const peakRange = Array.from(new Array(24)).map((_, index) => index as Hour);

    const { dates, range } = useMemo(
        () => {
            const allDates = insights?.map((item) => transformLabelDateFormat(item.date));
            const datesRange = [];
            if (allDates.length > 1) {
                datesRange.push(allDates.at(0));
                datesRange.push(allDates.at(-1));
            }

            return {
                dates: allDates,
                range: datesRange,
            };
        },
        [insights],
    );

    return (
        <Container>
            <SumaryContainer>
                <SummaryItem value={isLoading ? 'No Data' : String(callsPerDayAvg)} label="Avg. Call per Day" icon={<PhoneIcon />} isLoading={isLoading} />
                <SummaryItem value={isLoading ? 'No Data' : formatDuration(secondsPerDayAvg)} label="Avg. Minutes per Day" icon={<CalendarIcon />} isLoading={isLoading} />
                <SummaryItem value={isLoading ? 'No Data' : formatDuration(callDurationAvg)} label="Avg. Call Duration" icon={<ClockIcon />} isLoading={isLoading} />
                <SummaryItem value={isLoading ? 'No Data' : formatPeakHour(peakHour)} label="Peak Time" icon={<DoubleCheckIcon />} isLoading={isLoading} />
            </SumaryContainer>
            <ChartContainer
                isLoading={isLoading}
                label="Received Calls Statistics"
                description={`Total of calls ${totalCalls}`}
            >
                <StyledPlot
                    config={{
                        displayModeBar: false,
                        responsive: true,
                        autosizable: true,
                        doubleClick: false,
                        editable: false,
                        fillFrame: false,
                        scrollZoom: false,
                        showAxisDragHandles: false,
                        showAxisRangeEntryBoxes: false,
                        showTips: false,
                        showLink: false,
                        showEditInChartStudio: false,
                    }}
                    data={[
                        {
                            x: dates,
                            y: insights?.map((item) => item.total),
                            type: 'bar',
                            xaxis: 'x',
                            yaxis: 'y',
                            text: insights?.map((item) => (item.total > 0 ? item.total.toString() : '')),
                            textposition: 'outside',
                            cliponaxis: false,
                            name: 'Total calls',
                            marker: {
                                color: '#53D28C',
                            },
                            hoverinfo: 'text',
                            hovertext: insights.map(
                                (item) => `${item.total} calls at ${dateFormatterShort.format(DateTime.fromISO(item.date).toJSDate().getTime())}`,
                            ) || [],
                        },
                    ]}
                    layout={{
                        autosize: true,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        bargroupgap: 0.1,
                        margin: {
                            l: 30,
                            r: 20,
                            b: 50,
                            t: 10,
                        },
                        xaxis: {
                            fixedrange: true,
                            range,
                            // showgrid: false,
                            // zeroline: false,
                            // ticks: 'outside',
                            // tickcolor: 'transparent',
                            dtick: 'D1',
                            tickfont: {
                                size: 10,
                            },
                        },
                        yaxis: {
                            fixedrange: true,
                            rangemode: 'tozero',
                        },
                    }}
                />
            </ChartContainer>
            <ChartContainer
                isLoading={isLoading}
                label="Minutes Analysis"
                description={`Total of minutes ${formatDuration(totalSeconds)}`}
            >
                <StyledPlot
                    config={{
                        displayModeBar: false,
                        responsive: true,
                        autosizable: true,
                        doubleClick: false,
                        editable: false,
                        fillFrame: false,
                        scrollZoom: false,
                        showAxisDragHandles: false,
                        showAxisRangeEntryBoxes: false,
                        showTips: false,
                        showLink: false,
                        showEditInChartStudio: false,
                    }}
                    data={[
                        {
                            x: dates,
                            y: insights.map((item) => item.duration),
                            type: 'scatter',
                            mode: 'lines',
                            xaxis: 'x',
                            yaxis: 'y',
                            name: 'Total calls',
                            line: {
                                width: 4,
                                color: '#FFA266',
                            },
                            hoverinfo: 'text',
                            hovertext: insights.map(
                                (item) => `${formatDuration(item.duration)} at ${dateFormatterShort.format(DateTime.fromISO(item.date).toJSDate().getTime())}`,
                            ) || [],
                        },
                    ]}
                    layout={{
                        autosize: true,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        margin: {
                            l: 30,
                            r: 20,
                            b: 30,
                            t: 0,
                        },
                        xaxis: {
                            fixedrange: true,
                            range,
                        },
                        yaxis: {
                            fixedrange: true,
                        },
                    }}
                />
            </ChartContainer>
            <ChartContainer
                isLoading={isLoading}
                label="Peak Time"
                description={`${totalCalls} calls in the last 30 days`}
            >
                <HeatmapContainer
                    config={{
                        displayModeBar: false,
                        responsive: true,
                        autosizable: true,
                        doubleClick: false,
                        editable: false,
                        fillFrame: false,
                        scrollZoom: false,
                        showAxisDragHandles: false,
                        showAxisRangeEntryBoxes: false,
                        showTips: false,
                        showLink: false,
                        showEditInChartStudio: false,
                    }}
                    data={[
                        {
                            x: dates,
                            y: peakRange.map((hour) => `${hour}h`),
                            z: calculatePeakData(insights, peakRange),
                            type: 'heatmap',
                            colorscale: [
                                [0, '#E3E1FC'],
                                [1, '#6258CC'],
                            ],
                            showscale: false,
                            xgap: 5,
                            ygap: 5,
                            hovertemplate: '%{z} calls at %{x}<br />%{y}<extra></extra>',
                        },
                    ]}
                    layout={{
                        autosize: true,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        margin: {
                            l: 40,
                            r: 20,
                            b: 40,
                            t: 0,
                        },
                        xaxis: {
                            fixedrange: true,
                            range,
                            tickmode: 'auto',
                            nticks: 16,
                            tickfont: {
                                size: 10,
                            },
                        },
                        yaxis: {
                            fixedrange: true,
                        },
                    }}
                />
            </ChartContainer>
            <Footer />
        </Container>
    );
};

export default Insights;
