import TagsList from 'components/TagsList';
import { Workplace } from 'data/firestore/agent/workplace/types';
import {
    AvatarContainer,
    NameLabel,
    StyledLocationPin,
} from './styled';

interface WorkplaceInfoProps {
    workplace?: Workplace | null;
}

const WorkplaceInfo = ({
    workplace,
}: WorkplaceInfoProps) => (
    <AvatarContainer>
        <StyledLocationPin />
        <NameLabel>
            {workplace?.name}
        </NameLabel>
        <TagsList tags={workplace?.tags} />
    </AvatarContainer>
);

export default WorkplaceInfo;
