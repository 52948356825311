import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-rainbow-components';
import DateTimeColumn from 'components/DateTimeColumn';
import Table from 'components/Table';
import Plus from 'components/icons/plus';
import Button from 'components/Button';
import AddEditPhoneNumberModal from 'components/AddEditPhoneNumberModal';
import usePhoneNumbers from 'data/firestore/agent/phonenumber/useCollection';
import useManagePhoneNumbers from 'data/hooks/useManagePhoneNumbers';
import useUserRoles from 'hooks/useUserRoles';
import ActionsColumn from './columns/actions';
import { CellWrapper } from '../columns/styled';
import PhoneColumn from '../columns/phoneNumber';
import {
    StyledCard, EmptyLabel, TableContainer, Header, Title, Description, HeaderContent,
} from '../styled';
import FriendlyName from './columns/name';

const OutboundCallPhoneNumbers = () => {
    const { agentId = '' } = useParams();
    const { data: phoneNumbers, isLoading } = usePhoneNumbers(agentId, {
        disabled: !agentId,
    });
    const { create } = useManagePhoneNumbers({ agentId });
    const { role, isAdmin } = useUserRoles();
    const userCanWrite = useMemo(() => isAdmin || ['owner', 'editor'].includes(role as string), [role, isAdmin]);

    return (
        <>
            <StyledCard>
                <Header>
                    <HeaderContent>
                        <Title>Outbound Call Numbers</Title>
                        <Description>
                            These numbers are used for making outbound calls.
                        </Description>
                    </HeaderContent>
                    <Button
                        variant="outline-brand"
                        size="medium"
                        borderRadius="semi-rounded"
                        onClick={() => create()}
                    >
                        <Plus />
                        Add Number
                    </Button>
                </Header>
                <TableContainer>
                    <Table
                        data={phoneNumbers}
                        keyField="id"
                        isLoading={isLoading}
                    >
                        <Column
                            header="Phone number"
                            field="number"
                            component={PhoneColumn}
                        />
                        <Column
                            header="Friendly name"
                            field="friendlyName"
                            component={FriendlyName}
                        />
                        <Column
                            header="Created At"
                            field="createdAt"
                            component={DateTimeColumn}
                            emptyLabel={(
                                <CellWrapper>
                                    <EmptyLabel>-</EmptyLabel>
                                </CellWrapper>
                            )}
                        />
                        {userCanWrite && (
                            <Column
                                header="Actions"
                                component={ActionsColumn}
                                disabled={!userCanWrite}
                                defaultWidth={100}
                            />
                        )}
                    </Table>
                </TableContainer>
            </StyledCard>
            <AddEditPhoneNumberModal />
        </>
    );
};

export default OutboundCallPhoneNumbers;
