/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    MouseEvent,
    useCallback, useMemo, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { InternalOverlay } from 'react-rainbow-components';
import { useOpenModal, useOutsideClick, useWindowResize } from '@rainbow-modules/hooks';
import OpportunityIcon from 'components/icons/opportunity';
import { query, where } from 'firebase/firestore';
import useOpportunities from 'data/firestore/agent/opportunity/useCollection';
import { OPPORTUNITY_DETAILS_DRAWER } from '../../constants';
import {
    DropDown, StyledBadge, DropDownTitle, OpportunitiesList,
} from './styled';
import positionResolver from './helpers/resolver';
import OpportunityInfo from './opportunityInfo';

interface CustomerOpportunitiesBadgeProps {
    customersIds?: string[];
}

const CustomerOpportunitiesBadge = ({ customersIds = [] }: CustomerOpportunitiesBadgeProps) => {
    const { agentId = '' } = useParams();
    const triggerRef = useRef<any>(null);
    const dropdownRef = useRef<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openOpportunity] = useOpenModal(OPPORTUNITY_DETAILS_DRAWER);
    const { data = [], isLoading } = useOpportunities(agentId, {
        listQueryFn: (ref) => query(
            ref,
            where('customer.id', 'in', customersIds),
            where('removed', '==', false),
        ),
        disabled: !agentId || customersIds.length === 0,
    });

    const label = useMemo(() => {
        if (data.length > 1) return `${data.length} opportunities`;
        if (data.length === 1) return '1 opportunity';
        return '';
    }, [data]);

    const hideCustomer = useMemo(
        () => Array.from(new Set(customersIds)).length === 1,
        [customersIds],
    );

    const handleClick = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            if (data.length === 0) return;
            if (data.length === 1) {
                openOpportunity({ data: data[0] });
                return;
            }
            setIsOpen(!isOpen);
        },
        [data, isOpen, openOpportunity],
    );

    useOutsideClick(
        dropdownRef,
        (event) => {
            if (event.target !== triggerRef.current && !triggerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        },
        isOpen,
    );

    useWindowResize(() => setIsOpen(false), isOpen);

    if (isLoading || data.length === 0) return null;

    return (
        <>
            <div onClick={handleClick} ref={triggerRef}>
                <StyledBadge variant="brand">
                    <OpportunityIcon />
                    {label}
                </StyledBadge>
            </div>
            <InternalOverlay
                isVisible={isOpen}
                triggerElementRef={() => triggerRef}
                positionResolver={positionResolver}
            >
                <DropDown ref={dropdownRef}>
                    <DropDownTitle>Opportunities</DropDownTitle>
                    <OpportunitiesList>
                        {data.map((opportunity) => (
                            <OpportunityInfo
                                key={opportunity.id}
                                opportunity={opportunity}
                                hideCustomer={hideCustomer}
                                onDetailsClick={(opportunityData) => openOpportunity({
                                    data: opportunityData,
                                })}
                            />
                        ))}
                    </OpportunitiesList>
                </DropDown>
            </InternalOverlay>
        </>
    );
};

export default CustomerOpportunitiesBadge;
