import styled from 'styled-components';
import { Tab, Tabset } from 'react-rainbow-components';
import Card from 'components/Card';
import Button from 'components/Button';
import { GREY_800 } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: calc(100vh - 161px);
`;

export const Columns = styled.div`
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    align-items: start;
    box-sizing: border-box;
`;

export const LeftColumn = styled.div`
    max-width: 22.5rem;
    width: 100%;
    padding: 0;
    height: 100%;
    box-sizing: border-box;

    @media (max-width: 1023px) {
        max-width: unset;
    }
`;

export const StyledCard = styled(Card)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 166px);
    overflow: auto;
    box-sizing: border-box;
`;

export const CardContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
`;

export const RightColumn = styled(Card).withConfig({
    shouldForwardProp: (prop) => !['maxHeight'].includes(prop),
})<{ maxHeight?: number; }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
    ${(props) => props.maxHeight && `
        max-height: ${props.maxHeight + 0}px;
    `};
`;

export const TabLabel = styled.span`
    display: flex;
    padding: 0.625rem 1.25rem;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 0.02688rem;
`;

export const StyledTabset = styled(Tabset)`

`;

export const StyledTab = styled(Tab)`
    height: 4rem;
    display: flex;
    width: 9.75rem;
    height: 4rem;
    padding: 0rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    color: ${GREY_800};

    button::before {
        border-radius: 0;
        height: 2px;
    }

    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
`;

export const CardTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
    padding: 0.65rem 0.55rem 0.55rem 0.55rem;
    gap: 0.875rem;
`;

export const StyledButton = styled(Button)`
    flex-grow: 1;
    height: 3rem;
    line-height: 3rem;
`;
