import {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import CustomerActivity from 'components/CustomerActivity';
import { EntityGet } from 'data/firestore/types';
import useWorkplaceNotes from 'data/firestore/agent/workplace/note/useCollection';
import { showAppNotification } from '@rainbow-modules/app';
import useSendWorkplaceNote from 'data/hooks/useSendWorkplaceNote';
import { Chat } from 'data/firestore/agent/customer/chat/types';
import { CardContent } from '../styled';
import {
    NotesContainer,
    Gradient,
    StyledSendNoteForm,
} from './styled';

const WorkplaceNotes = () => {
    const { agentId = '', workplaceId = '' } = useParams();
    const messagesListRef = useRef<HTMLDivElement>(null);
    const [isSendingNote, setSendingNote] = useState(false);

    const { mutateAsync: sendNote } = useSendWorkplaceNote();

    const { data = [], isLoading: isLoadingNotes } = useWorkplaceNotes(
        agentId,
        workplaceId,
        {
            disabled: !agentId || !workplaceId,
            track: [agentId, workplaceId],
        },
    );

    const noteMessages = useMemo(
        () => data.map(({ text, attachedFiles, ...otherNoteData }) => ({
            ...otherNoteData,
            type: 'note',
            content: {
                text,
                attachedFiles,
            },
        })) as EntityGet<Chat>[],
        [data],
    );

    const handleSendNote = useCallback(
        async (note: string, files: File[]) => {
            setSendingNote(true);
            try {
                await sendNote({
                    agentId,
                    workplaceId,
                    note,
                    files,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            setSendingNote(false);
        },
        [agentId, sendNote, workplaceId],
    );

    useEffect(
        () => messagesListRef.current?.scrollBy(0, messagesListRef.current?.scrollHeight),
        [data],
    );

    return (
        <>
            <CardContent ref={messagesListRef}>
                <CustomerActivity
                    agentId={agentId}
                    isLoading={isLoadingNotes}
                    messages={noteMessages}
                    emptyMessage="No notes have been sent"
                />
            </CardContent>
            <NotesContainer>
                <Gradient />
                <StyledSendNoteForm
                    onSend={handleSendNote}
                    disableSend={isSendingNote}
                />
            </NotesContainer>
        </>
    );
};

export default WorkplaceNotes;
