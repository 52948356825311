import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-rainbow-components';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Button from 'components/Button';
import DateTimeColumn from 'components/DateTimeColumn';
import SearchIcon from 'components/icons/search';
import Plus from 'components/icons/plus';
import FormModal from 'components/FormModal';
import Table from 'components/Table';
import useHttpQuery from 'data/firestore/useHttpQuery';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useRedirectOnMissingRoles from 'hooks/useRedirectWhenMissingRoles';
import ModalTitle from 'components/ModalTitle';
import { formatPhoneNumber } from 'data/services/phone/formatter';
import ActionsColumn from './columns/actions';
import RoleColumn from './columns/role';
import UserColumn from './columns/user';
import { CellWrapper } from './columns/styled';
import { ADD_EDIT_TEAM_USER } from '../../../constants';
import Fields from './fields';
import {
    EmptyLabel,
    SearchContainer, SearchInput, TableContainer,
} from './styled';
import StatusColumn from './columns/status';
import PhoneNumberColumn from '../../PhoneNumbers/columns/phoneNumber';

interface Member {
    email: string;
    displayName?: string
    createdAt: Date;
    signedInAt: Date;
    role?: string;
}

const TeamPage = () => {
    const { agentId = '' } = useParams();
    const [search, setSearch] = useState('');
    const connectedModalProps = useConnectModal(ADD_EDIT_TEAM_USER);
    const [openModal, closeModal] = useOpenModal(ADD_EDIT_TEAM_USER);

    useRedirectOnMissingRoles({
        requiredRoles: ['owner'],
    });

    const {
        data = [],
        isFetched,
        refetch: refetchMembers,
    } = useHttpQuery<Member[]>({
        pathname: `/team/${agentId}/members`,
        queryOptions: {
            enabled: Boolean(agentId),
        },
    });

    const {
        mutateAsync: addMemberRequest,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        pathname: `/team/${agentId}/invite`,
        method: 'POST',
        onSuccess: () => {
            closeModal();
            refetchMembers();
        },
    });

    const {
        mutateAsync: updateMemberRequest,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        method: 'PATCH',
        onSuccess: () => {
            closeModal();
            refetchMembers();
        },
    });

    const addMember = useCallback(
        async (values: Record<string, unknown>) => {
            showAppSpinner();
            try {
                await addMemberRequest({
                    body: values,
                });
                showAppNotification({
                    title: 'Member Added Successfully',
                    description: `${values.email} has been added as a “${values.role}”.`,
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [addMemberRequest],
    );

    const updateMember = useCallback(
        async (row: Record<string, string>, values: Record<string, unknown>) => {
            showAppSpinner();
            try {
                const body: { role?: string, phoneNumber?: string } = {};
                if (values.role !== row.role) {
                    body.role = values.role as string;
                }
                if (values.phoneNumber) {
                    const phoneNumber = values.phoneNumber as {
                        countryCode: string,
                        phone: string
                    };
                    body.phoneNumber = `${phoneNumber.countryCode}${phoneNumber.phone}`;
                }
                await updateMemberRequest({
                    pathname: `/team/${agentId}/member/${row.uid}`,
                    body,
                });
                showAppNotification({
                    title: 'Member Updated Successfully',
                    description: `${values.email} has been updated successfully.`,
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [updateMemberRequest, agentId],
    );

    const isLoading = !isFetched;

    const filteredMembers = useMemo(() => {
        if (!search) return data;

        return data.filter((member) => {
            const { email = '', displayName = '' } = member;
            const term = search.toLowerCase();

            return email.toLowerCase().includes(term) || displayName.toLowerCase().includes(term);
        });
    }, [data, search]);

    return (
        <>
            <SearchContainer>
                <SearchInput
                    type="search"
                    placeholder="Search member..."
                    variant="bare"
                    icon={<SearchIcon />}
                    borderRadius="semi-square"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />
                <Button
                    variant="brand"
                    size="medium"
                    borderRadius="semi-square"
                    onClick={() => openModal({
                        title: <ModalTitle>Add Member</ModalTitle>,
                        message: 'Enter the member\'s email address and select their role to grant them access.',
                        submitButtonLabel: 'Add Member',
                        onSubmit: addMember,
                    })}
                >
                    <Plus className="rainbow-m-right_x-small" />
                    Add Member
                </Button>
            </SearchContainer>
            <TableContainer>
                <Table
                    data={filteredMembers}
                    keyField="uid"
                    isLoading={isLoading}
                >
                    <Column
                        header="User"
                        component={UserColumn}
                    />
                    <Column
                        header="Role"
                        field="role"
                        component={RoleColumn}
                    />
                    <Column
                        header="Created"
                        field="createdAt"
                        component={DateTimeColumn}
                        emptyLabel={(
                            <CellWrapper>
                                <EmptyLabel>-</EmptyLabel>
                            </CellWrapper>
                        )}
                    />
                    <Column
                        header="Phone Number"
                        field="phoneNumber"
                        component={PhoneNumberColumn}
                    />
                    <Column
                        header="Status"
                        field="status"
                        component={StatusColumn}
                    />
                    <Column
                        header="Actions"
                        component={ActionsColumn}
                        defaultWidth={140}
                        agentId={agentId}
                        onEdit={(row: Record<string, string>) => openModal({
                            title: <ModalTitle>Edit Member</ModalTitle>,
                            message: 'Adjust the member\'s role to change their access and roles in the portal.',
                            submitButtonLabel: 'Update',
                            disableEmail: true,
                            initialValues: {
                                email: row?.email,
                                role: row?.role,
                                phoneNumber: formatPhoneNumber(row?.phoneNumber),
                            },
                            onSubmit: (
                                values: Record<string, unknown>,
                            ) => updateMember(row, values),
                        })}
                        onDelete={() => refetchMembers()}
                    />
                </Table>
            </TableContainer>
            <FormModal
                borderRadius="semi-square"
                size="small"
                fields={Fields}
                {...connectedModalProps}
            />
        </>
    );
};

export default TeamPage;
