import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import {
    ElementContainer, EmptyLabel, IconContainer, FieldContainer, Label, Value,
} from './styled';

interface DetailElementProps {
    icon?: ReactNode;
    label?: ReactNode;
    value?: ReactNode;
    to?: string;
    [key: string]: any;
}

const DetailElement = ({
    icon, label, value, to,
}: DetailElementProps) => (
    <ElementContainer>
        <RenderIf isTrue={icon}>
            <IconContainer>
                {icon}
            </IconContainer>
        </RenderIf>
        <FieldContainer>
            <Label>
                {label}
            </Label>
            <Value>
                <RenderIf isTrue={to && value}>
                    <Link to={String(to)} target="_blank">{value}</Link>
                </RenderIf>
                <RenderIf isTrue={value && !to}>{value}</RenderIf>
                <RenderIf isTrue={!value}>
                    <EmptyLabel>Not Provided</EmptyLabel>
                </RenderIf>
            </Value>
        </FieldContainer>
    </ElementContainer>
);

export default DetailElement;
