import { Modal } from 'react-rainbow-components';
import styled from 'styled-components';
import UserAvatar from 'components/UserAvatar';
import Picklist from 'components/Picklist';
import Button from 'components/Button';
import UniversalForm from 'components/UniversalForm';
import { DIVIDER, GREY_800 } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

export const CustomerInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    padding: 1.5rem;
    margin: 0 1rem;
    border-bottom: 1px solid ${DIVIDER};
`;

export const StyledAvatar = styled(UserAvatar)`
    width: 7.5rem;
    height: 7.5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-weight: 300;

    svg {
        width: 4rem;
        height: 4rem;
    }

    abbr {
        font-size: 2.5rem;
    }
`;

export const NameLabel = styled.label`
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.75rem;
    color: ${GREY_800};
    margin: 0;
    text-align: center;
    margin-top: 1rem;
`;

export const PhoneLabel = styled.span`
    font-size: 1rem;
    font-weight: 400;
    color: #000;
`;

export const StyledPicklist = styled(Picklist)`
    width: 100%;
`;

export const StyledCallNowButton = styled(Button)`
    width: 100%;
    margin-top: 1rem;
`;

export const StyledModal = styled(Modal)`
    width: 360px;
    padding: 1rem !important;
`;

export const StyledUniversalForm = styled(UniversalForm)`
    width: 100%;
`;

export const FieldsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 1.5rem;
`;

export const Hint = styled.p`
    margin-top: -1.5rem;
    padding-top: 0.5rem;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.25rem;
    color: #88888D;
`;
