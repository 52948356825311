import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import Trash from 'components/icons/trash';
import ButtonIcon from 'components/ButtonIcon';
import ModalBody from 'components/ModalBody';
import { Workplace } from 'data/firestore/agent/workplace/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { EntityGet } from 'data/firestore/types';
import { ActionsColumnContainer } from './styled';

interface ActionsColumnProps {
    agentId: string;
    row?: EntityGet<Workplace>;
}

const ActionsColumn = ({
    agentId,
    row,
}: ActionsColumnProps) => {
    const { mutateAsync: deleteWorkplace } = useHttpMutation({
        method: 'DELETE',
    });

    const requestDeleteWorkplace = useCallback(
        async (workplaceId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title="Remove workplace?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteWorkplace({
                    pathname: `/agents/${agentId}/workplaces/${workplaceId}`,
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Workplace deleted successfuly.',
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deleteWorkplace, agentId],
    );

    return (
        <ActionsColumnContainer>
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Trash />}
                tooltip="Remove workplace"
                onClick={() => requestDeleteWorkplace(row?.id as string)}
            />
        </ActionsColumnContainer>
    );
};

export default ActionsColumn;
