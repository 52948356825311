import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import { CellWrapper, StyledStatusChip } from '../styled';

interface Props {
    row?: EntityGet<Session>;
}

const colorsMap: Record<string, 'base' | 'warning' | 'brand' | 'success' | 'error'> = {
    unknown: 'base',
    ringing: 'warning',
    'in-progress': 'brand',
    completed: 'success',
    cancelled: 'error',
    'no-answer': 'base',
    blocked: 'base',
};

const labelMap: Record<string, string> = {
    unknown: 'Unknown',
    ringing: 'Ringing',
    'in-progress': 'In Progress',
    completed: 'Completed',
    cancelled: 'Cancelled',
    'no-answer': 'No Answer',
    blocked: 'Blocked',
};

const StatusColumn = ({ row }: Props) => {
    const { status, _tags: tags } = row || {};
    const isSpam = tags?.includes('spam');
    const isTransferred = tags?.includes('transferred');
    return (
        <CellWrapper>
            <StyledStatusChip label={labelMap[status || 'unknown'] || 'Unknown'} size="small" variant={colorsMap[status || 'unknown'] || 'base'} borderRadius="semi-square" />
            {isSpam && <StyledStatusChip label="Spam" size="small" variant="error" borderRadius="semi-square" />}
            {isTransferred && <StyledStatusChip label="Transferred" size="small" variant="neutral" borderRadius="semi-square" />}
        </CellWrapper>
    );
};

export default StatusColumn;
