import styled, { css } from 'styled-components';
import { MultiSelect } from 'react-rainbow-components';
import Button from 'components/Button';
import { DIVIDER } from '../../constants';

export const StyledMultiSelect = styled(MultiSelect)`
    box-sizing: border-box;

    label + div {
        padding: 0.5rem 0.25rem;
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};
        
        &:focus,
        &:active,
        &:focus-within {
            padding: 0.5rem 0.25rem;
            border-radius: 0.375rem;
        }
    }

    button {
        margin: auto 0.25rem;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    position: relative;
`;

export const StyledButton = styled(Button)<{ hasError?: boolean }>`
    align-self: flex-end;
    ${(props) => props.hasError && css`
        position: absolute;
        bottom: -4px;
    `};
`;
