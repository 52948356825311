import ListTile from 'components/ListTile';
import { EntityGet } from 'data/firestore/types';
import { Tag } from 'data/firestore/agent/tag/types';
import { TagCellWrapper, StyledTagIcon } from '../styled';

interface Props {
    row?: EntityGet<Tag>;
}

const NameColumn = ({ row }: Props) => (
    <TagCellWrapper>
        <ListTile
            icon={<StyledTagIcon />}
            title={row?.name}
            subtitle={row?.description}
        />
    </TagCellWrapper>
);

export default NameColumn;
