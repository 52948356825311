import { useCallback, useMemo } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import useCustomerPanel from 'hooks/useCustomerPanel';
import { OPPORTUNITY_DETAILS_DRAWER } from '../../../../../constants';
import {
    OpportunityCellWrapper,
} from '../../../../Opportunities/View/List/columns/styled';
import {
    CardHeaderContent, CustomerName, CardTitle,
} from '../../../../Opportunities/View/Board/OpportunityCard/styled';
import { StyledCardHeader, CustomerAvatar } from './styled';

interface Props {
    row?: EntityGet<Opportunity>;
}

const OpportunityColumn = ({ row }: Props) => {
    const {
        id = '', firstName = '', lastName = '', alias = '', displayName: displayNameProp = '', photoURL, channel,
    } = row?.customer || {};

    const [openOpportunityDetailsModal] = useOpenModal(OPPORTUNITY_DETAILS_DRAWER);
    const { openCustomerPanel } = useCustomerPanel();

    const handleOpenDetails = useCallback(
        () => openOpportunityDetailsModal({ data: row }),
        [openOpportunityDetailsModal, row],
    );

    const handleOpenCustomerPanel = useCallback(
        () => openCustomerPanel({ data: row?.customer }),
        [openCustomerPanel, row],
    );

    const displayName = useMemo(
        () => getDisplayName({
            firstName,
            lastName,
            alias,
            displayName: displayNameProp,
        }),
        [firstName, lastName, alias, displayNameProp],
    );

    const customerInitials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    return (
        <OpportunityCellWrapper>
            <StyledCardHeader>
                <CustomerAvatar
                    size="medium"
                    initials={customerInitials}
                    src={photoURL}
                    value={id}
                    channel={channel}
                />
                <CardHeaderContent>
                    <CardTitle
                        type="button"
                        onClick={handleOpenDetails}
                    >
                        {row?.name}
                    </CardTitle>
                    <CustomerName
                        type="button"
                        onClick={handleOpenCustomerPanel}
                    >
                        {displayName}
                    </CustomerName>
                </CardHeaderContent>
            </StyledCardHeader>
        </OpportunityCellWrapper>
    );
};

export default OpportunityColumn;
