import { useConnectModal } from '@rainbow-modules/hooks';
import { ADD_EDIT_WIDGET_CHATBOT_MODAL } from '../../constants';
import Fields from './fields';
import {
    FormHeader, FormHeaderTitle, FormHeaderDescription, StyledModal,
} from './styled';

const AddEditWebsiteChatbotModal = () => {
    const {
        title, subtitle, ...connectedModalProps
    } = useConnectModal(ADD_EDIT_WIDGET_CHATBOT_MODAL);

    return (
        <StyledModal
            {...connectedModalProps}
            fields={Fields}
            title={(
                <FormHeader>
                    <FormHeaderTitle>{title}</FormHeaderTitle>
                    <FormHeaderDescription>{subtitle as string}</FormHeaderDescription>
                </FormHeader>
            )}
        />
    );
};

export default AddEditWebsiteChatbotModal;
