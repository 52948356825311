import React from 'react';

export interface Context {
    chatbots: Record<string, string>;
}

const context = React.createContext<Context>({
    chatbots: {},
});

export const { Provider, Consumer } = context;

export default context;
