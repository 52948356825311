import { useConnectModal } from '@rainbow-modules/hooks';
import FormModal from 'components/FormModal';
import { BaseFormProps } from './types';
import Fields from './fields';

const mapFormValues = (values: Record<string, unknown>) => values;

const CreateEditEntityTagForm = ({ modalId }: BaseFormProps) => {
    const {
        onSubmit, ...connectedModalProps
    } = useConnectModal(modalId);

    const onSubmitMiddleware = (formValues: Record<string, unknown>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (onSubmit) onSubmit(mapFormValues(formValues));
    };

    return (
        <FormModal
            {...connectedModalProps}
            borderRadius="semi-square"
            size="small"
            fields={Fields}
            onSubmit={onSubmitMiddleware}
        />
    );
};

export default CreateEditEntityTagForm;
