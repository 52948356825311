import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { Option } from 'react-rainbow-components';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { useReduxForm } from '@rainbow-modules/hooks';
import Diamonds from 'components/icons/diamonds';
import User from 'components/icons/user';
import useUserRoles from 'hooks/useUserRoles';
import { StyledPicklist } from '../styled';

interface CallerPickerProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string;
    onChange?: (value: string) => void;
}

const CallerPicker = (props: CallerPickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);
    const { user } = useUserRoles();

    const options = useMemo(() => [
        {
            name: 'sara', label: 'Sara AI Assistant', value: 'sara', icon: <Diamonds />,
        },
        {
            name: user?.id, label: 'Yourself', value: user?.id, icon: <User />,
        },
    ], [user]);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        return options.find((option) => option.value === valueInProps);
    }, [options, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );
    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`caller-picker-option__${option.name}`} {...option} />),
        [options],
    );

    return (
        <StyledPicklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
        >
            {pickListOptions}
        </StyledPicklist>
    );
};

export default CallerPicker;
