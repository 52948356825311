import { useCallback, useMemo } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import ModalTitle from 'components/ModalTitle';
import { Tag } from 'data/firestore/agent/tag/types';
import useHttpMutation from 'data/firestore/useHttpMutation';

const mapDataToFormValues = (data: Tag) => data;

const mapFormValuesToData = (values: Record<string, unknown>) => values;

const useManageEntityTags = ({
    agentId,
    modalId,
    entity,
    onCreated = () => {},
    onDeleted = () => {},
} : {
    agentId: string;
    modalId: string;
    entity?: Tag['entity'];
    onCreated?: (tag: Tag) => void
    onDeleted?: () => void;
}) => {
    const [openForm, closeForm] = useOpenModal(modalId);

    const { mutateAsync: createTag } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/tags/`,
        onSuccess: closeForm,
    });

    const { mutateAsync: updateTag } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closeForm,
    });

    const { mutateAsync: deleteTag } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreateTag = useCallback(
        (initialValues: Record<string, unknown> = {}) => openForm({
            title: 'Create New Tag',
            submitButtonLabel: 'Create',
            disabledFields: entity ? ['entity'] : [],
            initialValues: {
                entity,
                ...initialValues,
            },
            agentId,
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    const tag = await createTag({
                        body: mapFormValuesToData(values),
                    }) as Tag;
                    showAppNotification({
                        title: 'Success',
                        description: 'Tag added successfuly.',
                        icon: 'success',
                        timeout: 5000,
                    });
                    onCreated(tag);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, createTag, entity, onCreated, openForm],
    );

    const handleEditTag = useCallback(
        (data: Tag) => openForm({
            id: data?.id,
            agentId,
            disabledFields: ['entity', 'name'],
            title: <ModalTitle>Edit tag description</ModalTitle>,
            submitButtonLabel: 'Update',
            initialValues: mapDataToFormValues(data),
            onSubmit: async (values: Record<string, unknown>) => {
                const { description } = values;
                showAppSpinner();
                try {
                    await updateTag({
                        pathname: `/agents/${agentId}/tags/${data.id}`,
                        body: {
                            description,
                        },
                    });
                    showAppNotification({
                        title: 'Success',
                        description: 'Tag updated successfully.',
                        icon: 'success',
                        timeout: 5000,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [openForm, agentId, updateTag],
    );

    const handleDeleteTag = useCallback(
        async (tagId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title="Remove tag?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteTag({
                    pathname: `/agents/${agentId}/tags/${tagId}`,
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Tag deleted successfuly.',
                    icon: 'success',
                    timeout: 5000,
                });
                onDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deleteTag, agentId, onDeleted],
    );

    return useMemo(() => ({
        create: handleCreateTag,
        edit: handleEditTag,
        remove: handleDeleteTag,
        closeForm,
    }), [closeForm, handleCreateTag, handleDeleteTag, handleEditTag]);
};

export default useManageEntityTags;
