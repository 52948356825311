import { useOutletContext } from 'react-router-dom';
import { MarkdownOutput } from 'react-rainbow-components';
import { EntityGet } from 'data/firestore/types';
import { Workplace } from 'data/firestore/agent/workplace/types';
import { CardContent } from '../styled';

const WorkplaceDescription = () => {
    const { workplace } = useOutletContext<{ workplace?: EntityGet<Workplace> }>();

    return (
        <CardContent>
            <div className="rainbow-p-horizontal_large rainbow-p-vertical_small">
                <MarkdownOutput value={workplace?.description} />
            </div>
        </CardContent>
    );
};

export default WorkplaceDescription;
