import Footer from 'components/Footer';
import AIMessagesSentInWidgets from './AIMessagesSentInWidgetChatbot';
import { Container } from './styled';

const WebsiteChatbotsInsights = () => (
    <Container>
        <AIMessagesSentInWidgets
            title="AI-Sent Responses on Website Chatbot"
            description="View the AI-sent message volume on Website Chatbot for the selected time range."
            color="#2E2960"
        />
        <Footer />
    </Container>
);

export default WebsiteChatbotsInsights;
