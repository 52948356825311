import { useParams } from 'react-router-dom';
import ButtonIcon from 'components/ButtonIcon';
import Pencil from 'components/icons/pencil2';
import Trash from 'components/icons/trash';
import { Phonenumber } from 'data/firestore/agent/phonenumber/types';
import useManagePhoneNumbers from 'data/hooks/useManagePhoneNumbers';
import { ActionsCellWrapper } from './styled';

interface Props {
    row?: Record<string, string>;
    disabled?: boolean;
}

const ActionsColumn = ({
    row = {},
    disabled = false,
}: Props) => {
    const { agentId = '' } = useParams();
    const { edit, remove } = useManagePhoneNumbers({ agentId });

    return (
        <ActionsCellWrapper>
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Pencil />}
                tooltip="Edit phone number friendly name"
                size="small"
                disabled={disabled}
                onClick={() => edit(row as unknown as Phonenumber)}
            />
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Trash />}
                tooltip="Remove phone number"
                size="small"
                disabled={disabled}
                onClick={() => remove(row.id)}
            />
        </ActionsCellWrapper>
    );
};

export default ActionsColumn;
