import { useMemo } from 'react';
import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import getValueForAvatar from 'data/services/profile/getValueForAvatar';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import getAvatarIcon from 'data/services/profile/getAvatarIcon';
import { ItemUser } from 'data/firestore/globals';
import { StyledSaraAvatar, UserCellWrapper } from './styled';

interface Props {
    row?: EntityGet<Customer>;
    agentId?: string;
}

const UserCreatedByColumn = ({ row, agentId = '' }: Props) => {
    const { createdBy } = row || {};
    const displayName = useMemo(() => getDisplayName(createdBy), [createdBy]);
    const initials = useMemo(() => {
        if (displayName === 'Unknown' || createdBy?.role === 'agent') return null;
        return getInitials(displayName);
    }, [createdBy?.role, displayName]);

    const avatar = (
        createdBy?.role === 'agent'
            ? <StyledSaraAvatar />
            : (
                <UserAvatar
                    value={getValueForAvatar(createdBy as ItemUser, agentId)}
                    role={createdBy?.role}
                    initials={initials}
                    icon={getAvatarIcon(createdBy as ItemUser)}
                    src={createdBy?.photoURL}
                />
            )
    );

    return (
        <UserCellWrapper>
            <ListTile
                icon={avatar}
                title={displayName}
                subtitle={formatIntlPhoneNumber(createdBy?.phoneNumber) || createdBy?.role}
            />
        </UserCellWrapper>
    );
};

export default UserCreatedByColumn;
