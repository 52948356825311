import { useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import CalendarIcon from 'components/icons/calendarThin';
import Check from 'components/icons/check';
import Clock from 'components/icons/clock';
import Headphones from 'components/icons/headphones';
import IdCard from 'components/icons/idcard';
import Languages from 'components/icons/languages';
import Phone from 'components/icons/phone';
import User from 'components/icons/user';
import CallTypeBadge from 'components/CallTypeBadge';
import ConversationSummary from 'components/ConversationSummary';
import StatusBadge from 'components/StatusBadge';
import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import dateFormatter from 'data/services/date/formatter';
import formatDuration from 'data/services/duration/formatter';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import formatLanguages from 'data/services/language/formatter';
import SpamBadge from 'components/SpamBadge';
import { Customer } from 'data/firestore/agent/customer/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import UserAvatar from './userAvatar';
import {
    Row,
    Container,
    DetailsElements,
    SectionTitle,
} from './styled';
import DetailElement from './detail';

interface DetailsProps {
    sessionId?: string;
    isLoading?: boolean;
    data?: EntityGet<Session> & { customer: Customer | null };
}

const Details = ({
    sessionId = '',
    isLoading = false,
    data,
}: DetailsProps) => {
    const {
        id: uid, firstName, lastName, alias, phoneNumber = '', photoURL,
    } = data?.customer || {};

    const displayName = useMemo(
        () => getDisplayName({ firstName, lastName, alias }),
        [firstName, lastName, alias],
    );

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    return (
        <Container>
            <UserAvatar
                isLoading={isLoading}
                uid={uid}
                src={photoURL}
                initials={initials}
                displayName={displayName}
                phoneNumber={formatIntlPhoneNumber(phoneNumber)}
            />
            <ConversationSummary sessionId={sessionId} />
            <div className="rainbow-flex_column">
                <SectionTitle>Details</SectionTitle>
                <DetailsElements>
                    <DetailElement
                        icon={<CalendarIcon />}
                        label="Date:"
                        value={dateFormatter.format(data?.createdAt)}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Clock />}
                        label="Duration:"
                        value={formatDuration((data?.duration || 0) * 1000)}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Check />}
                        label="Status:"
                        value={(
                            <Row>
                                <StatusBadge value={data?.status} />
                                <RenderIf isTrue={data?._tags?.includes('spam')}>
                                    <SpamBadge />
                                </RenderIf>
                            </Row>
                        )}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Languages />}
                        label="Languages:"
                        value={formatLanguages(data?.language)}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Phone />}
                        label="Call Type:"
                        value={<CallTypeBadge value={data?.direction} />}
                        isLoading={isLoading}
                    />
                    <RenderIf isTrue={data?.direction === 'outbound'}>
                        <DetailElement
                            icon={<User />}
                            label="Handled By:"
                            value={data?.createdBy?.displayName || 'Sara AI'}
                            isLoading={isLoading}
                        />
                    </RenderIf>
                    <RenderIf isTrue={data?.direction === 'outbound'}>
                        <DetailElement
                            icon={<Phone />}
                            label="Used Phone Number:"
                            value={formatIntlPhoneNumber(data?.from)}
                            isLoading={isLoading}
                        />
                    </RenderIf>
                    <DetailElement
                        icon={<Phone />}
                        label="Phone Type:"
                        value="Mobile"
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<IdCard />}
                        label="Conversation ID:"
                        value={sessionId}
                        isLoading={isLoading}
                    />
                    <RenderIf isTrue={data?.direction === 'inbound'}>
                        <DetailElement
                            icon={<Headphones />}
                            label="Assistant:"
                            value={formatIntlPhoneNumber(data?.to)}
                            isLoading={isLoading}
                        />
                    </RenderIf>
                </DetailsElements>
            </div>
        </Container>
    );
};

export default Details;
