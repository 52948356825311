import { useMemo } from 'react';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import Phone from 'components/icons/phone';
import {
    PhoneCellWrapper, Icon, Label,
} from './styled';

interface Props {
    value?: string;
    emptyLabel?: string;
}

const PhoneColumn = ({ value = '', emptyLabel = '-' }: Props) => {
    const formattedNumber = useMemo(() => formatIntlPhoneNumber(value), [value]);
    const isEmpty = !value || value === '';
    if (isEmpty) {
        return (
            <PhoneCellWrapper>
                {emptyLabel || '-'}
            </PhoneCellWrapper>
        );
    }
    return (
        <PhoneCellWrapper>
            <Icon
                value={value}
                icon={<Phone />}
            />
            <Label>{formattedNumber}</Label>
        </PhoneCellWrapper>
    );
};

export default PhoneColumn;
