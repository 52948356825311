import styled from 'styled-components';
import { Chip } from 'react-rainbow-components';
import { Link } from 'react-router-dom';
import { ColorType, color } from 'theme';
import Phone from 'components/icons/phone';
import {
    GREY_300, ORANGE_100, ORANGE_500,
} from '../../../constants';

export const Container = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: wrap;
`;

export const LeftCellWrapper = styled(CellWrapper)`
    padding: 0 20px;
`;

export const UserCellWrapper = styled(CellWrapper)`
    padding: 10px 10px;
`;

export const SourceIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    background-color: ${ORANGE_100};
    color: ${ORANGE_500};
`;

export const PhoneIcon = styled(Phone)`
    width: 1.125rem;
    height: 1.125rem;
`;

export const StyledLink = styled(Link)`
    color: ${color('brand.main')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: ${color('brand.dark')};
    }
`;

export const StyledStatusChip = styled(Chip)`
    ${(props) => ['brand', 'success', 'warning', 'error'].some((item) => item === props.variant) && `
        color: ${color(`${props.variant}.dark` as ColorType)(props)};
        background-color: ${color(`${props.variant}.light` as ColorType)(props)};
        border-color: ${color(`${props.variant}.light` as ColorType)(props)};
    `}
`;
