import { useCallback, useContext } from 'react';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import context from 'components/OutboundCallProvider/context';

interface Props {
    agentId: string;
    customerId: string;
}

interface Req {
    personaId?: string;
    transferTo?: string;
}

interface Res {
    code: string;
    message: string;
    callSid: string;
}

interface Params {
    responderId?: string;
    phoneNumber?: string;

}

const useCallCustomer = ({ agentId, customerId }: Props) => {
    const { mutateAsync: callWithAIPersona } = useHttpMutation<Req, Res>({
        method: 'POST',
        pathname: `/agents/${agentId}/customers/${customerId}/call`,
    });

    const { mutateAsync: callUsingPhone } = useHttpMutation<Req, Res>({
        method: 'POST',
        pathname: `/agents/${agentId}/customers/${customerId}/call-bridge`,
    });

    const { addOutboundCall } = useContext(context);

    const callCustomer = useCallback(async ({ responderId, phoneNumber }: Params) => {
        try {
            showAppSpinner();
            const callFn = (
                responderId ? callWithAIPersona : callUsingPhone
            );
            const { callSid } = await callFn({
                body: (responderId ? { personaId: responderId } : { transferTo: phoneNumber }),
            });
            addOutboundCall(callSid);
        } catch (error) {
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [addOutboundCall, callUsingPhone, callWithAIPersona]);

    return {
        callCustomer,
    };
};

export default useCallCustomer;
