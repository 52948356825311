import { Field } from 'react-final-form';
import { Email } from '@rainbow-modules/icons';
import { isEmail } from '@rainbow-modules/validation';
import RolePicker from 'components/RolePicker';
import { PhoneInput as PhoneNumberInput, RenderIf } from 'react-rainbow-components';
import { FieldsContainer, StyledInput, Message } from './styled';

interface Params {
    disableEmail?: boolean;
    message?: string;
}

const validateEmail = (value?: string) => {
    if (!isEmail(value as string)) {
        return 'Please enter a valid email address';
    }
    return undefined;
};

const validateRole = (value?: string) => {
    if (!['viewer', 'editor'].includes(value as string)) {
        return 'Please select a valid role.';
    }
    return undefined;
};

const Fields = ({
    disableEmail = false,
    message = '',
}: Params) => (
    <>
        <Message>{message}</Message>
        <FieldsContainer>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="email"
                label="Email Address"
                labelAlignment="left"
                icon={<Email />}
                placeholder="johndoe@gmail.com"
                borderRadius="semi-square"
                autoComplete="off"
                required
                disabled={disableEmail}
                validate={validateEmail}
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={RolePicker}
                name="role"
                label="Role"
                labelAlignment="left"
                placeholder="Select role"
                borderRadius="semi-square"
                required
                validate={validateRole}
            />
            <RenderIf isTrue={disableEmail}>
                <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                    component={PhoneNumberInput}
                    name="phoneNumber"
                    label="Phone Number"
                    labelAlignment="left"
                    placeholder="1234567890"
                    borderRadius="semi-square"
                />
            </RenderIf>
        </FieldsContainer>
    </>
);

export default Fields;
