import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { orderBy, query } from 'firebase/firestore';
import Footer from 'components/Footer';
import SearchIcon from 'components/icons/search';
import Button from 'components/Button';
import Plus from 'components/icons/plus';
import { Column } from 'react-rainbow-components';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import CreateEditEntityTagForm from 'components/CreateEditEntityTagForm';
import useTags from 'data/firestore/agent/tag/useCollection';
import useManageEntityTags from 'data/hooks/useManageEntityTags';
import useQueryPaging from 'hooks/useQueryPaging';
import UserCreatedByColumn from 'pages/Customers/CustomersList/columns/createdBy';
import {
    Container,
    StyledCard,
    SearchContainer,
    SearchInput,
    PaginationContainer,
    EntriesText,
    TableContainer,
} from './styled';
import { ADD_EDIT_ENTITY_TAG_MODAL, PAGE_SIZE } from '../../constants';
import NameColumn from './columns/name';
import EntityColumn from './columns/entity';
import ActionsColumn from './columns/actions';

const TagsManagementPage = () => {
    const { agentId = '' } = useParams();
    const [search, setSearch] = useState('');

    const { create: createTag } = useManageEntityTags({
        agentId,
        modalId: ADD_EDIT_ENTITY_TAG_MODAL,
    });

    const {
        data: allTags = [], isLoading,
    } = useTags(agentId as string, {
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'asc')),
    });

    const filteredTags = useMemo(() => {
        if (!search) return allTags;
        return allTags.filter((tag) => {
            const regex = new RegExp(search, 'gi');
            return regex.test(tag.name) || regex.test(tag.description || '');
        });
    }, [search, allTags]);

    const totalHits = filteredTags.length;

    const { activePage, gotoPage } = useQueryPaging({
        maxPages: Math.ceil(totalHits / PAGE_SIZE),
    });

    const {
        data: tagsInPage,
        pages,
        firstItemOfActivePage,
        lastItemOfActivePage,
        setPage,
    } = useMemo(
        () => {
            const start = Math.min(PAGE_SIZE * (activePage - 1), totalHits);
            const end = Math.min(PAGE_SIZE * activePage, totalHits);
            const dataToShow = filteredTags.slice(start, end);

            return ({
                data: dataToShow,
                pages: Math.ceil(totalHits / PAGE_SIZE),
                firstItemOfActivePage: start + 1,
                lastItemOfActivePage: end,
                setPage: (page: number) => {
                    if (isLoading) return;
                    if (page === activePage) return;
                    gotoPage(page);
                },
            });
        },
        [activePage, totalHits, filteredTags, isLoading, gotoPage],
    );

    const handlePageChange = useCallback(
        (event: React.ChangeEvent<unknown>, page: number) => setPage(page),
        [setPage],
    );

    return (
        <Container>
            <StyledCard>
                <SearchContainer>
                    <SearchInput
                        type="search"
                        placeholder="Search tag..."
                        variant="bare"
                        icon={<SearchIcon />}
                        borderRadius="semi-square"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                    <Button variant="brand" size="medium" borderRadius="semi-square" onClick={() => createTag()}>
                        <Plus className="rainbow-m-right_x-small" />
                        New
                    </Button>
                </SearchContainer>
                <TableContainer>
                    <Table
                        keyField="id"
                        data={tagsInPage}
                        isLoading={isLoading}
                    >
                        <Column header="Tag" field="name" component={NameColumn} />
                        <Column header="Entity" field="entity" width={200} component={EntityColumn} />
                        <Column header="Created By" field="createdBy" component={UserCreatedByColumn} width={350} />
                        <Column header="Actions" component={ActionsColumn} width={130} agentId={agentId} />
                    </Table>

                    <PaginationContainer>
                        <EntriesText>
                            Showing
                            {' '}
                            {firstItemOfActivePage}
                            {' '}
                            to
                            {' '}
                            {lastItemOfActivePage}
                            {' '}
                            of
                            {' '}
                            {totalHits}
                            {' '}
                            entries.
                        </EntriesText>
                        <Pagination
                            activePage={activePage}
                            pages={pages}
                            onChange={handlePageChange}
                        />
                    </PaginationContainer>
                </TableContainer>
            </StyledCard>
            <Footer />
            <CreateEditEntityTagForm modalId={ADD_EDIT_ENTITY_TAG_MODAL} />
        </Container>
    );
};

export default TagsManagementPage;
