import { Column } from 'react-rainbow-components';
import Table from 'components/Table';
import { FeaturesListProps } from '../types';
import {
    Header,
    Title,
    SubTitle,
} from '../styled';
import DescriptionColumn from '../columns/description';
import FeatureColumn from '../columns/feature';
import AIPersonaColumn from '../columns/aiPersona';

const CallSkills = ({
    agentId,
    group,
    features = [],
    isLoading,
}: FeaturesListProps) => (
    <>
        <Header>
            <Title>{group?.label}</Title>
            <SubTitle>{group?.description}</SubTitle>
        </Header>
        <Table isLoading={isLoading} keyField="name" data={features}>
            <Column
                header="Name"
                field="label"
                component={FeatureColumn}
            />
            <Column
                header="Description"
                field="description"
                component={DescriptionColumn}
            />
            <Column
                header="AI Persona"
                agentId={agentId}
                component={AIPersonaColumn}
                width={280}
            />
        </Table>
    </>
);

export default CallSkills;
