import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { query, where } from 'firebase/firestore';
import { CheckboxGroupProps } from 'react-rainbow-components/components/CheckboxGroup';
import LoadingShape from 'components/LoadingShape';
import useTags from 'data/firestore/agent/tag/useCollection';
import { Tag } from 'data/firestore/agent/tag/types';
import { Label, StyledCheckBoxGroup } from './styled';

interface Params extends Omit<CheckboxGroupProps, 'value' | 'onChange'> {
    agentId?: string;
    entity?: Tag['entity'];
    value?: string[] | null;
    onChange?: (values: string[] | null) => void;
}

const EntityTagsFilter = ({
    agentId = '',
    entity,
    ...props
}: Params) => {
    const {
        value: valueInProps,
        onChange = () => {},
        ...rest
    } = useReduxForm(props);

    const { data: tags = [], isLoading } = useTags(
        agentId,
        {
            listQueryFn: (ref) => query(ref, where('entity', '==', entity)),
            disabled: !agentId,
            track: [agentId],
        },
    );

    const options = useMemo(
        () => tags.map(
            (tag) => ({
                value: tag.name,
                label: <Label>{tag.name}</Label>,
            }),
        ),
        [tags],
    );

    const value = useMemo(
        () => {
            if (valueInProps) return valueInProps;
            return tags.map((tag) => tag.name);
        },
        [valueInProps, tags],
    );

    const handleOnChange = useCallback(
        (values: string[]) => {
            if (values.length === tags.length) return onChange(null);
            return onChange(values);
        },
        [tags.length, onChange],
    );

    if (isLoading) {
        return (
            <>
                <LoadingShape width="30%" />
                <LoadingShape width="40%" />
                <LoadingShape width="10%" />
            </>
        );
    }

    return (
        <StyledCheckBoxGroup
            {...rest}
            hideLabel
            options={options}
            value={value}
            onChange={handleOnChange}
        />
    );
};

export default EntityTagsFilter;
