import { Field } from '@rainbow-modules/forms';
import { validateNotEmpty } from 'data/validators';
import { FieldsContainer, StyledInput } from './styled';

const Fields = () => (
    <FieldsContainer>
        <Field
            name="name"
            component={StyledInput}
            label="Name"
            placeholder="Enter a name for the website chatbot"
            labelAlignment="left"
            borderRadius="semi-square"
            required
            validate={validateNotEmpty('This field is required')}
        />
    </FieldsContainer>
);

export default Fields;
