import styled from 'styled-components';
import FormModal from 'components/FormModal';
import Input from 'components/Input';
import { DIVIDER, GREY_400, GREY_800 } from '../../constants';

export const StyledModal = styled(FormModal)`
    width: 500px;
    padding: 24px !important;
`;

export const FormHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem 1rem;
    gap: 0.5rem;
`;

export const FormHeaderTitle = styled.h1`
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 2.25rem;
    color: ${GREY_800};
`;

export const FormHeaderDescription = styled.p`
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_400};
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1rem 1rem;
    gap: 1rem;
`;

export const StyledInput = styled(Input)`
    flex: 1;
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    label + div > span {
        color: ${GREY_400};
        width: 1.5rem;
        flex-shrink: 0;
        
        svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
            font-size: 1.5rem !important;
        }        
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;
