import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Phone from 'components/icons/phone';
import { Customer } from 'data/firestore/agent/customer/types';
import { EntityGet } from 'data/firestore/types';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import useUserRoles from 'hooks/useUserRoles';
import useCallCustomer from 'hooks/useCallCustomer';
import {
    Container, CustomerInfoContainer, NameLabel,
    PhoneLabel, StyledAvatar, StyledCallNowButton,
    StyledUniversalForm,
} from './styled';
import Fields from './fields';
import { FormValues } from './types';

interface CustomerCallModalProps {
    customer: EntityGet<Customer>;
    onRequestClose: () => void;
}

const ModalBody = ({ customer, onRequestClose }: CustomerCallModalProps) => {
    const { agentId = '' } = useParams();
    const { user } = useUserRoles();
    const {
        id, photoURL, phoneNumber: customerPhoneNumber,
    } = customer;
    const displayName = getDisplayName(customer);

    const { callCustomer } = useCallCustomer({ agentId, customerId: id });

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const handleCallNow = async (values: FormValues) => {
        const { callScript: responderId, phoneNumber } = values;
        await callCustomer({ responderId, phoneNumber });
        onRequestClose();
    };

    return (
        <Container>
            <CustomerInfoContainer>
                <StyledAvatar
                    src={photoURL}
                    assistiveText={displayName}
                    initials={initials}
                    title={displayName}
                    value={id}
                />
                <NameLabel>
                    {displayName}
                </NameLabel>
                <PhoneLabel>
                    {formatIntlPhoneNumber(customerPhoneNumber)}
                </PhoneLabel>
            </CustomerInfoContainer>
            <StyledUniversalForm id="call-customer-form" initialValues={{ caller: user?.id }} onSubmit={handleCallNow}>
                <Fields agentId={agentId} />
            </StyledUniversalForm>
            <StyledCallNowButton
                type="submit"
                form="call-customer-form"
                variant="brand"
                size="medium"
                borderRadius="semi-rounded"
            >
                <Phone className="rainbow-m-right_x-small" />
                Call Now
            </StyledCallNowButton>
        </Container>
    );
};

export default ModalBody;
