import { RenderIf } from 'react-rainbow-components';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import { orderBy, query } from 'firebase/firestore';
import { Customer } from 'data/firestore/agent/customer/types';
import { Conversation } from 'data/firestore/session/conversation/types';
import useConversation from 'data/firestore/session/conversation/useCollection';
import AudioPlayer from '../../../../components/AudioPlayer';
import ConversationMessage from './message';
import { MessagesList, SectionHeader } from './styled';
import RecordingMessage from './recordingMesage';

interface TranscriptProps {
    sessionId?: string;
    recordingId?: string;
    from?: Customer | null;
    status?: string;
}

const Transcript = ({
    sessionId = '',
    recordingId = '',
    from,
    status = '',
}: TranscriptProps) => {
    const { data: messages, isLoading: isLoadingConversation } = useConversation(sessionId, {
        listQueryFn: (ref) => query(ref, orderBy('createdAt')),
        disabled: !sessionId,
        track: [sessionId],
    });

    const [postcallData, isLoadingPostCallData] = useDocOnce({
        path: `/session/${sessionId}/metadata/postcall`,
        flat: true,
        disabled: !sessionId,
    });

    const filteredMessages = messages.filter((message) => message.role === 'assistant' || message.role === 'user');

    const conversation = (
        filteredMessages.length > 0
            ? filteredMessages
            : (postcallData?.metadata?.call?.conversation || []) as Conversation[]
    ).map((message, index) => ({
        ...message,
        id: message.id || `${index}`,
    }));

    const isLoading = isLoadingPostCallData || isLoadingConversation;

    return (
        <MessagesList>
            <RenderIf isTrue={status === 'completed' && recordingId}>
                <AudioPlayer recordingId={recordingId} />
            </RenderIf>
            <SectionHeader>Transcription</SectionHeader>
            <RenderIf isTrue={status === 'in-progress'}>
                <RecordingMessage />
            </RenderIf>
            <RenderIf isTrue={isLoading}>
                <ConversationMessage isLoading={isLoading} />
            </RenderIf>
            <RenderIf isTrue={!isLoading && filteredMessages.length}>
                {conversation.map((message) => (
                    <ConversationMessage
                        key={message.id}
                        from={from}
                        role={message.role as string}
                        content={message.content}
                    />
                ))}
            </RenderIf>
            <RenderIf isTrue={!isLoading && !conversation.length}>
                <ConversationMessage
                    from={from}
                    content="No messages in this transcript."
                />
            </RenderIf>

        </MessagesList>
    );
};

export default Transcript;
