/* eslint-disable react/jsx-no-undef */
import { isDefined } from '@rainbow-modules/validation';
import { Geometry } from 'types';
import getLocation from 'helpers/getLocation';
import resolveTimezone from 'helpers/resolveTimezone';
import getE164Number from 'data/services/phone/getE164Number';
import { FormContainer, StyledUniversalForm } from './styled';
import Fields from './fields';

interface FormProps {
    id?: string,
    initialValues?: Record<string, unknown>,
    agentId: string;
    onSubmit?: (values: Record<string, unknown>) => void,
}

interface FormValues extends Record<string, unknown> {
    name?: string;
    description?: string;
    phoneNumber: Record<string, unknown>;
    email?: string;
    address?: Record<string, unknown>;
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    tags?: string[];
}

const getPhoneValue = (value?: {
    countryCode?: string;
    isoCode?: string;
    phone?: string;
}) => (value?.phone ? getE164Number(`${value?.countryCode}${value?.phone}`) : '');

const mapFormValues = async (currentValues: FormValues) => {
    const {
        phoneNumber,
        address,
        facebook,
        instagram,
        linkedin,
        ...rest
    } = currentValues;

    let newAddress;
    if (address && typeof address !== 'string') {
        const { lat, lng } = getLocation(address?.geometry as Geometry);
        (address.geometry as Geometry).location = { lat, lng };
        newAddress = {
            addressInfo: address,
            formattedAddress: address?.formatted_address,
            timezone: await resolveTimezone(lat, lng),
        };
    }

    const result: Record<string, unknown> = {
        ...rest,
        address: newAddress,
        phoneNumber: getPhoneValue(phoneNumber),
        socialLinks: {
            facebook,
            instagram,
            linkedin,
        },
    };

    return Object.keys(result).reduce((acc: Record<string, unknown>, key) => {
        if (isDefined(result[key])) {
            acc[key] = result[key];
        }
        return acc;
    }, {});
};

const Form = ({
    id,
    initialValues,
    agentId,
    onSubmit,
}: FormProps) => {
    const onSubmitMiddleware = async (formValues: Record<string, unknown>) => {
        if (onSubmit) onSubmit(await mapFormValues(formValues as FormValues));
    };

    return (
        <FormContainer>
            <StyledUniversalForm
                id={id}
                onSubmit={onSubmitMiddleware}
                initialValues={initialValues}
            >
                <Fields agentId={agentId} />
            </StyledUniversalForm>
        </FormContainer>
    );
};

export default Form;
