import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import Trash from 'components/icons/trash';
import ButtonIcon from 'components/ButtonIcon';
import ModalBody from 'components/ModalBody';
import PhoneIcon from 'components/icons/phone';
import SmsIcon from 'components/icons/sms';
import FacebookSolid from 'components/icons/facebookSolid';
import InstagramSolid from 'components/icons/instagramSolid';
import RenderIfAdmin from 'components/RenderIfAdmin';
import { Customer } from 'data/firestore/agent/customer/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { EntityGet } from 'data/firestore/types';
import useSMSChat from 'hooks/useSMSChat';
import { CUSTOMER_CALL_MODAL } from '../../../../../constants';
import { ActionsColumnContainer } from '../../../WorkplacesList/columns/styled';
import { StyledBadgeOverlay, StyledLink } from './styled';

interface ActionsColumnProps {
    agentId: string;
    row?: EntityGet<Customer>;
}

const ActionsColumn = ({
    agentId,
    row,
}: ActionsColumnProps) => {
    const { openChat } = useSMSChat();

    const handleSMSClick = useCallback(
        () => openChat({ ...row }),
        [openChat, row],
    );

    const [openCustomerCallModal] = useOpenModal(CUSTOMER_CALL_MODAL);

    const { mutateAsync: removeFromWorkpace } = useHttpMutation({
        method: 'PATCH',
    });

    const requestRemoveCustomer = useCallback(
        async (customerId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title="Remove customer from this workplace?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await removeFromWorkpace({
                    pathname: `/customers/${customerId}`,
                    body: {
                        agentId,
                        workplaceId: null,
                    },
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Workplace deleted successfuly.',
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [removeFromWorkpace, agentId],
    );

    return (
        <ActionsColumnContainer>
            <RenderIf isTrue={row?.phoneNumber}>
                <RenderIfAdmin>
                    <ButtonIcon
                        borderRadius="semi-rounded"
                        icon={<PhoneIcon />}
                        onClick={() => openCustomerCallModal({ customer: row })}
                    />
                </RenderIfAdmin>
            </RenderIf>
            <RenderIf isTrue={row?.phoneNumber}>
                <ButtonIcon
                    borderRadius="semi-rounded"
                    icon={(
                        <StyledBadgeOverlay
                            overlap="circle"
                            isHidden={!row?.hasNewMessage}
                        >
                            <SmsIcon />
                        </StyledBadgeOverlay>
                    )}
                    onClick={handleSMSClick}
                />
            </RenderIf>
            <RenderIf isTrue={row?.instagramProfileURL}>
                <StyledLink to={String(row?.instagramProfileURL)} target="_blank">
                    <InstagramSolid />
                </StyledLink>
            </RenderIf>
            <RenderIf isTrue={row?.facebookProfileURL}>
                <StyledLink to={String(row?.facebookProfileURL)} target="_blank">
                    <FacebookSolid />
                </StyledLink>
            </RenderIf>
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Trash />}
                tooltip="Remove customer from workplace"
                onClick={() => requestRemoveCustomer(row?.id as string)}
            />
        </ActionsColumnContainer>
    );
};

export default ActionsColumn;
