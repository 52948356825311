import styled, { css } from 'styled-components';
import { ProgressCircular } from 'react-rainbow-components';
import Badge from 'components/Badge';
import ListTile from 'components/ListTile';
import {
    GREY_300, GREY_400, PURPLE_500, PURPLE_700, WHITE,
} from '../../constants';

export const StyledBadge = styled(Badge)`
    user-select: none;
    cursor: pointer;
    padding: 0.25rem 0.5rem 0.25rem 0.375rem;
`;

export const DropDown = styled.div`
    display: flex;
    width: 23.75rem;
    padding: 1rem 1.25rem 1.25rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1.5rem;
    gap: 0,5rem;
    background: ${WHITE};
    box-shadow: 0px 4px 16px 0px rgba(75, 70, 92, 0.10);
`;

export const DropDownTitle = styled.h2`
    color: #4B465C;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem; 
    padding-bottom: 0.25rem;
`;

export const OpportunitiesList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    max-height: 15rem;
`;

export const InfoContainer = styled.div`
    display: flex;
    padding: 1rem;
    align-items: start;
    gap: 1rem;
    align-self: stretch;
    border-radius: 0.75rem;
    background: #F5F5F6;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const StyledProgressCircular = styled(ProgressCircular)`
    min-width: 4.5rem;
    width: 4.5rem;
    height: 4.5rem;

    svg {
        width: 4.2rem;
        height: 4.2rem;
        overflow: visible !important;
        
        circle {
            stroke-width: 4px;
        }
    }

    & > h1 {
        font-size: 1px;
        color: ${WHITE};
        &::after {
            color: ${GREY_400};
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 1.4105rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;

            ${(props) => props.variant && css`
                content: '${props.value}';

            `}
        }
    }

    ${(props) => !props.variant && css`
        color: ${GREY_400};
        & > h1 {
            color: ${WHITE};
            ::after {
                color: ${GREY_300};
                content: '-';
                position: absolute;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }
    `};
`;

export const OppotunityName = styled.h3`
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
`;

export const DetailsButton = styled.button`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    display: flex;
    padding: 0;
    color: ${PURPLE_500};
    white-space: nowrap;
    background: none;
    border: none;

    &:hover {
        color: ${PURPLE_700};
        text-decoration: underline;
    }
`;

export const StyledListTile = styled(ListTile)`
    & > div ~ div > span {
        font-size: 13px;
    }
`;
