import styled from 'styled-components';
import Input from 'components/Input';
import { DIVIDER, GREY_200, GREY_300 } from '../../../constants';

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
`;

export const SearchInput = styled(Input)`
    flex: 1;
`;

export const TableContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const EmptyLabel = styled.span`
    font-style: italic;
    color: ${GREY_300};
    font-weight: 300;
    font-size: 0.9375rem;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    align-content: center;
    gap: 1rem;
    padding: 1.5rem 0 1rem;


    & > div {
        flex: 1;
    }
`;

export const StyledInput = styled(Input)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const Message = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_200};
    text-align: center;
    padding-top: 0.75rem;
`;
