import transformDateRangeToAlgoliaFilter from './transformDateRangeToAlgoliaFilter';
import transformTagsToAlgoliaFilter from './transformTagsToAlgoliaFilter';

const buildAlgoliaFilters = ({
    filters = {},
    agentId,
}: {
    agentId: string;
    filters?: Record<string, string | string[] | null>;
}) => [
    `agentId:${agentId}`,
    ...Object.keys(filters).reduce((acc, key) => {
        const filterValue = filters[key];
        if (key === 'createdAt') {
            const transformedValue = transformDateRangeToAlgoliaFilter(filterValue as string);
            if (transformedValue) return [...acc, transformedValue];
        }
        if (key === 'tags') {
            const transformedValue = transformTagsToAlgoliaFilter(filterValue as string);
            if (transformedValue) return [...acc, transformedValue];
        }
        return acc;
    }, [] as string[]),
].join(' AND ');

export default buildAlgoliaFilters;
