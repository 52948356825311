import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { Outlet, useMatch, useParams } from 'react-router-dom';
import { ButtonOption, CheckboxToggle, RenderIf } from 'react-rainbow-components';
import { orderBy, query, where } from 'firebase/firestore';
import { isEmpty } from '@rainbow-modules/validation';
import { DateTime } from 'luxon';
import Button from 'components/Button';
import ButtonIcon from 'components/ButtonIcon';
import Badge from 'components/Badge';
import CreateEditOpportunityForm from 'components/CreateEditOpportunityForm';
import Insights from 'components/icons/insight';
import SearchIcon from 'components/icons/search';
import BoardIcon from 'components/icons/board';
import TableViewIcon from 'components/icons/tableView';
import Plus from 'components/icons/plus';
import SortByPicker, { SortByPickerValue } from 'components/SortByPIcker';
import useStoredSort from 'hooks/useStoredSort';
import usePipelines from 'data/firestore/agent/pipeline/useCollection';
import usePipelineStages from 'data/firestore/agent/pipeline/stage/useCollection';
import useOpportunities from 'data/firestore/agent/opportunity/useCollection';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import { EntityGet } from 'data/firestore/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import tryParseNumber from 'data/services/string/parseNumber';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import useSearchFilters from 'hooks/useSearchFilters';
import useUserRoles from 'hooks/useUserRoles';
import { ViewContextType } from '../types';
import {
    Container,
    StyledCard,
    SearchContainer,
    SearchInput,
    Content,
    StyledBadgeOverlay,
    StyledButtonGroupPicker,
    RightContent,
} from './styled';
import useManageOpportunities from '../../../data/hooks/useManageOpportunities';
import FilterButton from './filterButton';
import sortOpportunities from './helpers/sortOpportunituesFn';

const dataSearchFilters = ['search', 'owner', 'score', 'stage', 'createdAt', 'amount'];

const sortFields = [
    { name: 'score', label: 'Score' },
    { name: 'createdAt', label: 'Create Date' },
    { name: 'updatedAt', label: 'Last Updated' },
];

const defaultSort: SortByPickerValue<string> = {
    sortBy: 'createdAt',
    order: 'asc',
};

const checkFilters = ({
    value,
    filterValues,
    filterNames,
}: {
    value: EntityGet<Opportunity>,
    filterValues: Record<string, unknown>;
    filterNames: string[],
}) => filterNames.every(
    (filterName) => {
        const filterValue = filterValues[filterName];

        if (filterValue === null) return true;

        if (filterName === 'search') {
            const { name, customer } = value;
            const {
                phoneNumber: customerPhoneNumber = '',
                email: customerEmail = '',
            } = customer;
            const customerDisplayName = getDisplayName(customer);

            const term = (filterValue as string).toLowerCase().trim();
            const phoneNumberTerm = term.replaceAll(/\/[s++()]+\//gi, '');

            return (
                name.toLowerCase().includes(term)
                || customerEmail.toLowerCase().includes(term)
                || customerDisplayName.toLowerCase().includes(term)
                || customerPhoneNumber.includes(phoneNumberTerm)
            );
        }

        if (filterName === 'score') {
            const score = value.score as number;
            if ((filterValue as string).length === 0) return false;
            return (filterValue as string).split(',').some(
                (rangeName: string) => {
                    if (rangeName.toLowerCase() === 'high') return score >= 70;
                    if (rangeName.toLowerCase() === 'medium') return score >= 40 && score < 70;
                    if (rangeName.toLowerCase() === 'low') return score !== null && score < 40;
                    return score === null;
                },
            );
        }

        if (filterName === 'stage') {
            if ((filterValue as string).length === 0) return false;
            return (filterValue as string).split(',').some(
                (stage: string) => stage === value.stageId,
            );
        }

        if (filterName === 'amount') {
            if ((filterValue as string).length === 0) return false;
            const [min, max] = (filterValue as string).split(',').map((numericValue) => tryParseNumber(numericValue));
            const valueAmount = (value.amount || 0) / 100;
            let condition = true;
            if (min) { condition = condition && valueAmount >= min; }
            if (max) { condition = condition && valueAmount <= max; }
            return condition;
        }

        if (filterName === 'owner') {
            if ((filterValue as string).length === 0) return false;
            return (filterValue as string).split(',').some(
                (ownerId: string) => (
                    value.owner?.uid
                        ? ownerId === value.owner.uid
                        : false
                ),
            );
        }

        if (filterName === 'createdAt') {
            if ((filterValue as string).length === 0) return false;
            const [from, to = DateTime.now().toMillis()] = (filterValue as string).split(',').map((date: string) => DateTime.fromISO(date).toMillis());
            const valueTime = value.createdAt.getTime();
            return valueTime >= from && valueTime <= to;
        }

        return true;
    },
);

const OpportunitiesViewPage = () => {
    const { agentId = '' } = useParams();
    const { isAdmin } = useUserRoles();
    const navigate = useNavigateWithQuery();
    const match = useMatch(':agentId/opportunities/view/:viewType');
    const [editColumnsMode, setEditColumnMode] = useState(false);

    const [sort, setSort] = useStoredSort('opportunities');

    const {
        values: filterValues,
        getValue: getFilterValue,
        setValue: setFilterValue,
    } = useSearchFilters({
        filterNames: dataSearchFilters,
    });

    const activeFiltersCount = useMemo(
        () => dataSearchFilters.reduce(
            (count, key) => {
                if (key === 'search') return count;
                return (
                    getFilterValue(key) !== null
                        ? count + 1
                        : count
                );
            },
            0,
        ),
        [getFilterValue],
    );

    const { create: createNewOpportunity } = useManageOpportunities({ agentId });

    const [activePipelineId, setActivePipelineId] = useState<string>('');

    const {
        data: pipelines = [],
        isLoading: isLoadingPipelines,
    } = usePipelines(
        agentId,
        {
            disabled: !agentId,
            track: [agentId],
        },
    );

    const {
        data: pipelineStages = [],
        isLoading: isLoadingPipelineStages,
    } = usePipelineStages(
        agentId,
        activePipelineId,
        {
            disabled: !agentId || !activePipelineId,
            track: [activePipelineId],
        },
    );

    const {
        data: opportunities = [],
        isLoading: isLoadingOpportunities,
    } = useOpportunities(
        agentId,
        {
            disabled: !agentId,
            listQueryFn: (ref) => query(
                ref,
                where('removed', '==', false),
                orderBy('createdAt', 'desc'),
            ),
            track: [agentId],
        },
    );

    const orderedPipelineStages = useMemo(
        () => {
            const pipelineStageOrder = pipelines.find(
                (pipeline) => pipeline.id === activePipelineId,
            )?.stages || [];

            return pipelineStageOrder.reduce(
                (activeStages: EntityGet<Stage>[], stageId: string) => {
                    const found = pipelineStages.find((stage) => stage.id === stageId);
                    if (found) {
                        return [...activeStages, found];
                    }

                    return activeStages;
                },
                [],
            );
        },
        [activePipelineId, pipelineStages, pipelines],
    );

    const handleAddNewOpportunity = useCallback(
        () => createNewOpportunity({}),
        [createNewOpportunity],
    );

    useEffect(
        () => {
            if (!isLoadingPipelines && !activePipelineId && pipelines.length > 0) {
                const [firstPipeline] = pipelines;
                setActivePipelineId(firstPipeline?.id);
                setFilterValue('stage', null);
            }
        },
        [activePipelineId, isLoadingPipelines, pipelines, setFilterValue],
    );

    useEffect(() => {
        if (match?.params.viewType !== 'board') {
            setEditColumnMode(false);
        }
    }, [match]);

    const filteredData = useMemo(
        () => {
            const filterdOpportunities = opportunities.reduce(
                (result: EntityGet<Opportunity>[], opportunity) => {
                    if (checkFilters({
                        value: opportunity,
                        filterValues,
                        filterNames: dataSearchFilters,
                    })) {
                        return [
                            ...result,
                            opportunity,
                        ];
                    }
                    return result;
                },
                [],
            );

            const { sortBy, order: sortOrder } = sort || defaultSort;
            return filterdOpportunities.sort(sortOpportunities(sortBy, sortOrder));
        },
        [opportunities, sort, filterValues],
    );

    const contextValue = useMemo(
        () => ({
            selectedPipeline: activePipelineId,
            isLoading: isLoadingPipelines || isLoadingPipelineStages || isLoadingOpportunities,
            pipelines,
            stages: orderedPipelineStages,
            opportunities: filteredData,
            totalHits: filteredData.length,
            searchQuery: getFilterValue('search') || '',
            editColumnsMode,
        }) satisfies ViewContextType,
        [
            activePipelineId,
            isLoadingPipelines,
            isLoadingPipelineStages,
            isLoadingOpportunities,
            pipelines,
            orderedPipelineStages,
            filteredData,
            editColumnsMode,
            getFilterValue,
        ],
    );

    const searchResultsLabel = useMemo(() => {
        if (isEmpty(getFilterValue('search'))) return '';
        return [
            filteredData.length,
            (
                filteredData.length === 1
                    ? 'result'
                    : 'results'
            ),
            'found',
        ].join(' ');
    }, [filteredData.length, getFilterValue]);

    return (
        <>
            <Container>
                <Content>
                    <StyledCard>
                        <SearchContainer>
                            <StyledButtonGroupPicker
                                value={match?.params?.viewType}
                                onChange={(viewType) => {
                                    navigate(viewType as string);
                                }}
                            >
                                <ButtonOption name="board" label={<BoardIcon />} />
                                <ButtonOption name="list" label={<TableViewIcon />} />
                            </StyledButtonGroupPicker>
                            <SearchInput
                                type="search"
                                placeholder="Search opportunity..."
                                variant="bare"
                                icon={<SearchIcon />}
                                borderRadius="semi-rounded"
                                onChange={(e) => setFilterValue('search', e.target.value)}
                                value={getFilterValue('search') || ''}
                                disabled={editColumnsMode}
                            />
                            <RenderIf isTrue={!isEmpty(searchResultsLabel)}>
                                <Badge className="rainbow-m-right_x-large">
                                    {searchResultsLabel}
                                </Badge>
                            </RenderIf>
                            <RightContent>
                                <RenderIf isTrue={match?.params?.viewType === 'board' && isAdmin}>
                                    <CheckboxToggle
                                        label="Edit Mode"
                                        value={editColumnsMode}
                                        disabled={!isEmpty(getFilterValue('search'))}
                                        onChange={() => setEditColumnMode(!editColumnsMode)}
                                    />
                                </RenderIf>
                                <SortByPicker
                                    sortFields={sortFields}
                                    value={sort}
                                    onChange={setSort}
                                    prefixLabel="Sort by:"
                                    iconPosition="right"
                                    borderRadius="semi-rounded"
                                />
                                <ButtonIcon
                                    icon={<Insights />}
                                    onClick={() => navigate(`/${agentId}/insights/opportunities`)}
                                    borderRadius="semi-rounded"
                                    tooltip="View Insights"
                                />
                                <StyledBadgeOverlay
                                    value={activeFiltersCount}
                                    isHidden={activeFiltersCount === 0}
                                    variant="brand"
                                    position="top-left"
                                >
                                    <FilterButton
                                        label="Filters"
                                        agentId={agentId}
                                        pipelineId={activePipelineId}
                                    />
                                </StyledBadgeOverlay>
                                <Button
                                    variant="brand"
                                    size="medium"
                                    borderRadius="semi-rounded"
                                    onClick={handleAddNewOpportunity}
                                >
                                    <Plus className="rainbow-m-right_small" />
                                    New
                                </Button>
                            </RightContent>
                        </SearchContainer>
                        <Outlet context={contextValue} />
                    </StyledCard>
                </Content>
            </Container>
            <CreateEditOpportunityForm />
        </>
    );
};

export default OpportunitiesViewPage;
