import { query, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import CustomersInfo from 'components/CustomersInfo';
import useCustomers from 'data/firestore/agent/customer/useCollection';
import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import { UserCellWrapper } from '../styled';

interface Props {
    row?: EntityGet<Session>;
}

const UserColumn = ({ row }: Props) => {
    const { agentId = '' } = useParams<{ agentId?: string }>();
    const {
        from = '',
        to = '',
        direction = '',
    } = row || {};

    const customerPhoneNumber = direction === 'inbound' ? from : to;
    const { data: customers = [], isLoading } = useCustomers(agentId, {
        listQueryFn: (ref) => query(ref, where('phoneNumber', '==', customerPhoneNumber)),
        disabled: !customerPhoneNumber,
    });
    const mappedCustomers = (customers || []).map((c) => ({ ...c, role: 'customer' as const }));

    return (
        <UserCellWrapper>
            <CustomersInfo customers={mappedCustomers} isLoading={isLoading} />
        </UserCellWrapper>
    );
};

export default UserColumn;
