import TagsList from 'components/TagsList';
import { EntityGet } from 'data/firestore/types';
import { Workplace } from 'data/firestore/agent/workplace/types';
import { CellWrapper } from './styled';

interface Props {
    row?: EntityGet<Workplace>;
}

const TagsColumn = ({ row }: Props) => (
    <CellWrapper>
        <TagsList tags={row?.tags} align="left" />
    </CellWrapper>
);

export default TagsColumn;
