import Trash from 'components/icons/trash';
import Pencil from 'components/icons/pencil2';
import ButtonIcon from 'components/ButtonIcon';
import { Tag } from 'data/firestore/agent/tag/types';
import { EntityGet } from 'data/firestore/types';
import useManageEntityTags from 'data/hooks/useManageEntityTags';
import { ADD_EDIT_ENTITY_TAG_MODAL } from '../../../constants';
import { ActionsColumnContainer } from '../styled';

interface ActionsColumnProps {
    agentId: string;
    row?: EntityGet<Tag>;
}

const ActionsColumn = ({
    agentId,
    row,
}: ActionsColumnProps) => {
    const { edit: editTag, remove: removeTag } = useManageEntityTags({
        agentId,
        modalId: ADD_EDIT_ENTITY_TAG_MODAL,
    });

    return (
        <ActionsColumnContainer>
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Pencil />}
                tooltip="Edit tag description"
                onClick={() => editTag(row as Tag)}
            />
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Trash />}
                tooltip="Remove tag"
                onClick={() => removeTag(row?.id as string)}
            />
        </ActionsColumnContainer>
    );
};

export default ActionsColumn;
