import { useParams } from 'react-router-dom';
import usePhoneNumbers from 'data/firestore/agent/phonenumber/useCollection';
import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import useAgentData from 'hooks/useAgentData';
import ListTile from 'components/ListTile';
import SaraAvatar from 'components/icons/saraAvatar';
import useHttpQuery from 'data/firestore/useHttpQuery';
import UserAvatar from 'components/UserAvatar';
import getInitials from 'data/services/string/getInitials';
import { UserCellWrapper } from '../styled';

interface Props {
    row?: EntityGet<Session>;
}

interface Member {
    uid: string;
    email: string;
    displayName?: string
    createdAt: Date | null;
    role?: string;
    phoneNumber?: string;
    photoUrl?: string;
}

const HandledByColumn = ({ row }: Props) => {
    const { agentId = '' } = useParams<{ agentId?: string }>();
    const { agent } = useAgentData();
    const {
        from = '',
        to = '',
        direction = '',
        createdBy,
    } = row || {};

    const handledByPhoneNumber = direction === 'inbound' ? to : from;
    const { _phoneNumbers: agentPhoneNumbers = [] } = agent || {};
    const {
        data: teamMembers = [],
    } = useHttpQuery<Member[]>({
        pathname: `/team/${agentId}/members`,
        queryOptions: {
            enabled: Boolean(agentId),
        },
    });
    const { data: phoneNumbers = [] } = usePhoneNumbers(agentId, {
        disabled: !agentId,
    });

    if (agentPhoneNumbers.includes(handledByPhoneNumber)) {
        return (
            <UserCellWrapper>
                <ListTile
                    icon={<SaraAvatar />}
                    title="Sara AI"
                />
            </UserCellWrapper>
        );
    }

    const teamMember = teamMembers.find((m) => m.phoneNumber === handledByPhoneNumber);
    if (teamMember) {
        return (
            <UserCellWrapper>
                <ListTile
                    icon={(
                        <UserAvatar
                            src={teamMember.photoUrl}
                            initials={getInitials(teamMember.displayName)}
                            value={teamMember.uid || teamMember.email}
                        />
                    )}
                    title={teamMember.displayName || 'Team Member'}
                />
            </UserCellWrapper>
        );
    }

    if (createdBy) {
        return (
            <UserCellWrapper>
                <ListTile
                    icon={(
                        <UserAvatar
                            src={createdBy.photoURL}
                            initials={getInitials(createdBy.displayName)}
                            value={createdBy.uid}
                        />
                    )}
                    title={createdBy.displayName}
                />
            </UserCellWrapper>
        );
    }

    const phoneNumber = phoneNumbers.find((p) => p.number === handledByPhoneNumber);
    if (phoneNumber) {
        return (
            <UserCellWrapper>
                <ListTile
                    icon={(
                        <UserAvatar
                            initials={getInitials(phoneNumber.friendlyName)}
                            value={phoneNumber.id}
                        />
                    )}
                    title={phoneNumber.formatInternational}
                    subtitle={phoneNumber.friendlyName}
                />
            </UserCellWrapper>
        );
    }

    return null;
};

export default HandledByColumn;
