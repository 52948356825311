import ButtonIcon from 'components/ButtonIcon';
import { Widgetchatbot } from 'data/firestore/agent/widgetchatbot/types';
import useManageWebsiteChatbots from 'data/hooks/useManageWebsiteChatbots';
import Pencil from 'components/icons/pencil3';
import Trash from 'components/icons/trash';
import { ActionsCellWrapper } from './styled';

interface Props {
    agentId: string;
    row?: {
        data: Widgetchatbot,
    };
}

const WidgetChatbotActionsColumn = ({ agentId, row }: Props) => {
    const { edit, remove } = useManageWebsiteChatbots({ agentId });
    return (
        <ActionsCellWrapper>
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Pencil />}
                tooltip="Edit chatbot name"
                size="small"
                onClick={() => edit(row?.data as Widgetchatbot)}
            />
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Trash />}
                tooltip="Delete chatbot"
                size="small"
                onClick={() => remove(row?.data.id as string)}
            />
        </ActionsCellWrapper>
    );
};

export default WidgetChatbotActionsColumn;
