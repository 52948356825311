import { useCallback } from 'react';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import ButtonIcon from 'components/ButtonIcon';
import Pencil from 'components/icons/pencil2';
import useAgentData from 'hooks/useAgentData';
import useUpdateAgentMutation from 'data/hooks/useUpdateAgentMutation';
import { EDIT_PHONE_LANGUAGE } from '../../../../constants';
import { ActionsCellWrapper } from './styled';

interface FormValues extends Record<string, unknown> {
    preferredLanguage: 'en' | 'es';
}

interface Props {
    row?: Record<string, string>;
    disabled?: boolean;
}

const ActionsColumn = ({
    row = {},
    disabled = false,
}: Props) => {
    const { agent } = useAgentData();
    const [openModal, closeModal] = useOpenModal(EDIT_PHONE_LANGUAGE);
    const {
        mutateAsync: updateAgent,
    } = useUpdateAgentMutation(agent?.id as string);

    const handleSubmit = useCallback(
        async (values: FormValues) => {
            const { id, preferredLanguage } = values;
            const phoneNumbers = agent?.phoneNumbers.map(
                ({ id: numberId, language }) => ({
                    id: numberId,
                    language: (
                        numberId === id
                            ? preferredLanguage
                            : language
                    ),
                }),
            );
            try {
                showAppSpinner();
                await updateAgent({
                    body: {
                        phoneNumbers,
                    },
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Your changes have been saved.',
                    icon: 'success',
                    timeout: 5000,
                });
                closeModal();
            } catch (error: any) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agent?.phoneNumbers, closeModal, updateAgent],
    );

    const editRow = useCallback(
        (phoneRow: Record<string, string>) => openModal({
            title: 'Change Preferred Language',
            message: '',
            submitButtonLabel: 'Save',
            initialValues: {
                id: row.id,
                preferredLanguage: phoneRow.language,
            },
            onSubmit: handleSubmit,
        }),
        [handleSubmit, openModal, row.id],
    );

    return (
        <ActionsCellWrapper>
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Pencil />}
                tooltip="Edit phone number language"
                size="small"
                disabled={disabled}
                onClick={() => editRow(row)}
            />
        </ActionsCellWrapper>
    );
};

export default ActionsColumn;
