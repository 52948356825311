import { isEmpty } from '@rainbow-modules/validation';

const transformTagsToAlgoliaFilter = (value?: string) => {
    if (!value || value.length === 0) return undefined;
    const algoliaFilters = value.split(',').reduce((acc, tag: string) => {
        if (!isEmpty(tag)) return [...acc, `tags:${tag}`];
        return acc;
    }, [] as string[]);

    if (algoliaFilters.length > 1) return `(${algoliaFilters.join(' OR ')})`;

    return algoliaFilters.join('');
};

export default transformTagsToAlgoliaFilter;
