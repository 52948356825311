import { useCallback, useMemo } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import { PhoneInputValue } from 'react-rainbow-components/components/PhoneInput';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import ModalTitle from 'components/ModalTitle';
import { Phonenumber } from 'data/firestore/agent/phonenumber/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { formatPhoneNumber } from 'data/services/phone/formatter';
import { ADD_EDIT_PHONE_NUMBER_MODAL } from '../../constants';

const mapDataToFormValues = (data: Phonenumber) => ({
    friendlyName: data.friendlyName,
    phone: formatPhoneNumber(data.number),
});

const mapFormValuesToData = (values: Record<string, unknown>) => {
    const { friendlyName, phone } = values;
    const { countryCode, phone: phoneNumber } = phone as PhoneInputValue;
    return {
        friendlyName,
        phoneNumber: `${countryCode}${phoneNumber}`,
    };
};

const useManagePhoneNumbers = ({
    agentId,
    onPhoneCreated = () => {},
    onPhoneDeleted = () => {},
} : {
    agentId: string;
    onPhoneCreated?: (phone: Phonenumber) => void
    onPhoneDeleted?: () => void;
}) => {
    const [openForm, closeForm] = useOpenModal(ADD_EDIT_PHONE_NUMBER_MODAL);

    const { mutateAsync: createPhoneNumber } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/phone-numbers/`,
        onSuccess: closeForm,
    });

    const { mutateAsync: updatePhoneNumber } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closeForm,
    });

    const { mutateAsync: deletePhoneNumber } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreatePhoneNumber = useCallback(
        (initialValues: Record<string, unknown> = {}) => openForm({
            // title: <ModalTitle>Add New Phone Number</ModalTitle>,
            title: 'Add New Phone Number',
            subtitle: 'These numbers are used for making outbound calls.',
            submitButtonLabel: 'Add',
            initialValues: {
                type: 'open',
                ...initialValues,
            },
            agentId,
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    const phone = await createPhoneNumber({
                        body: mapFormValuesToData(values),
                    }) as Phonenumber;
                    showAppNotification({
                        title: 'Success',
                        description: 'Phone added successfuly.',
                        icon: 'success',
                        timeout: 5000,
                    });
                    onPhoneCreated(phone);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, createPhoneNumber, onPhoneCreated, openForm],
    );

    const handleEditPhoneNumber = useCallback(
        (data: Phonenumber) => openForm({
            id: data?.id,
            agentId,
            disabledFields: ['phone'],
            title: <ModalTitle>Edit Phone Number</ModalTitle>,
            subtitle: 'These numbers are used for making outbound calls.',
            submitButtonLabel: 'Save',
            initialValues: mapDataToFormValues(data),
            onSubmit: async (values: Record<string, unknown>) => {
                const { friendlyName } = values;
                showAppSpinner();
                try {
                    await updatePhoneNumber({
                        pathname: `/agents/${agentId}/phone-numbers/${data.id}`,
                        body: {
                            friendlyName,
                        },
                    });
                    showAppNotification({
                        title: 'Success',
                        description: 'Your changes have been saved.',
                        icon: 'success',
                        timeout: 5000,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [openForm, agentId, updatePhoneNumber],
    );

    const handleDeletePhoneNumber = useCallback(
        async (phoneId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title="Remove phone number?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deletePhoneNumber({
                    pathname: `/agents/${agentId}/phone-numbers/${phoneId}`,
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Phone deleted successfuly.',
                    icon: 'success',
                    timeout: 5000,
                });
                onPhoneDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deletePhoneNumber, agentId, onPhoneDeleted],
    );

    return useMemo(() => ({
        create: handleCreatePhoneNumber,
        edit: handleEditPhoneNumber,
        remove: handleDeletePhoneNumber,
        closeForm,
    }), [closeForm, handleCreatePhoneNumber, handleDeletePhoneNumber, handleEditPhoneNumber]);
};

export default useManagePhoneNumbers;
