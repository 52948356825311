import { useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import UserAvatar from 'components/UserAvatar';
import getVariantFromScore from 'components/OpportunityDetailsDrawer/helpers/getVariantFromScore';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import {
    Info, InfoContainer, OppotunityName, DetailsButton, StyledProgressCircular,
    StyledListTile,
} from './styled';

const OpportunityInfo = ({
    opportunity,
    hideCustomer = false,
    onDetailsClick = () => {},
}: {
    opportunity: Opportunity;
    hideCustomer?: boolean;
    onDetailsClick?: (opportunity: Opportunity) => void;
}) => {
    const {
        id = '', firstName = '', lastName = '', alias = '', displayName: displayNameProp = '', photoURL,
    } = opportunity?.customer || {};

    const displayName = useMemo(
        () => getDisplayName({
            firstName,
            lastName,
            alias,
            displayName: displayNameProp,
        }),
        [firstName, lastName, alias, displayNameProp],
    );

    const customerInitials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    return (
        <InfoContainer>
            <StyledProgressCircular
                variant={getVariantFromScore(opportunity.score)}
                value={opportunity.score || 0}
            />
            <Info>
                <OppotunityName>{opportunity.name}</OppotunityName>
                <RenderIf isTrue={!hideCustomer}>
                    <StyledListTile
                        icon={(
                            <UserAvatar
                                size="small"
                                initials={customerInitials}
                                src={photoURL}
                                value={id}
                            />
                        )}
                        title={displayName}
                    />
                </RenderIf>
                <DetailsButton onClick={() => onDetailsClick(opportunity)}>
                    See all details
                </DetailsButton>
            </Info>
        </InfoContainer>
    );
};

export default OpportunityInfo;
