import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';
import { PicklistProps } from 'react-rainbow-components/components/Picklist';

interface PickerListProps extends Omit<PicklistProps, 'value' | 'onChange'> {
    value?: string;
    onChange?: (value: string) => void;
    options?: PicklistValue[];
}

const PickerList = (props: PickerListProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        options = [],
        ...rest
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        return options.find((option) => option.value === valueInProps);
    }, [options, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`picker-option__${option.name}`} {...option} />),
        [options],
    );

    return (
        <Picklist
            {...rest}
            labelAlignment="left"
            value={pickListvalue}
            onChange={handleOnChange}
        >
            {pickListOptions}
        </Picklist>
    );
};

export default PickerList;
