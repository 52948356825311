import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BadgeOverlay } from 'react-rainbow-components';
import { GREY_100, GREY_500, PURPLE_500 } from '../../../../../constants';

export const StyledBadgeOverlay = styled(BadgeOverlay)`
    & > span {
        width: 0.625rem;
        height: 0.625rem;
        box-sixing: border-box;
        border: 2px solid ${GREY_100};
        right: 0.325rem;
        border-radius: 100%;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${GREY_500};
    padding: 12px;
    border-radius: 0.75rem;
    background: transparent;
    width: 3rem;
    height: 3rem;
    align-items: center;
    display: flex;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
        color: ${PURPLE_500};
        background: rgb(227, 224, 252);
    }
`;
