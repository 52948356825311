import { useCallback, useMemo } from 'react';
import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import { UserCellWrapper } from '../styled';

interface Props {
    row?: EntityGet<Customer>;
    disableLink?: boolean;
    onClick?: (customer: EntityGet<Customer>) => void;
}

const UserColumn = ({ row, disableLink = false, onClick }: Props) => {
    const {
        id = '', firstName = '', lastName = '', alias = '', displayName: displayNameProp = '', phoneNumber, photoURL,
    } = row || {};
    const displayName = useMemo(
        () => getDisplayName({
            firstName,
            lastName,
            alias,
            displayName: displayNameProp,
        }),
        [firstName, lastName, alias, displayNameProp],
    );

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const LinkTo = useMemo(() => (disableLink ? undefined : `${id}/activity`), [disableLink, id]);

    const handleClick = useCallback(() => onClick?.(row as EntityGet<Customer>), [row, onClick]);

    return (
        <UserCellWrapper>
            <ListTile
                icon={(
                    <UserAvatar
                        initials={initials}
                        src={photoURL}
                        value={id}
                    />
                )}
                to={LinkTo}
                title={displayName}
                onClick={onClick && handleClick}
                subtitle={formatIntlPhoneNumber(phoneNumber)}
            />
        </UserCellWrapper>
    );
};

export default UserColumn;
