import { ReactNode, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useUserRoles from 'hooks/useUserRoles';
import Insights from 'components/icons/insight';
import CalendarIcon from 'components/icons/calendar';
import InboxIcon from 'components/icons/inbox';
import SettingIcon from 'components/icons/setting';
import CircleIcon from 'components/icons/circle';
import User from 'components/icons/user';
import TasksIcon from 'components/icons/tasks';
import Dollar from 'components/icons/dollar';
import Users from 'components/icons/users';
import Book from 'components/icons/book';
import Building from 'components/icons/building';
import RenderIfRole from 'components/RenderIfRole';
import ButtonIcon from 'components/ButtonIcon';
import SidebarExpandIcon from 'components/icons/sidebarExpand';
import SidebarShrinkIcon from 'components/icons/sidebarShrink';
import SkillsIcon from 'components/icons/skills';
import { RenderIf } from 'react-rainbow-components';
import PhoneThin from 'components/icons/phone';
import Social from 'components/icons/social';
import Shine from 'components/icons/shine';
import RenderIfAdmin from 'components/RenderIfAdmin';
import Opportunity from 'components/icons/opportunity';
import useOpenTasks from 'hooks/useOpenTasks';
import usePendingApprovalEventsCount from 'data/hooks/usePendingApprovalEventsCount';
import {
    Container,
    Header,
    Options,
    OptionsContainer,
    Separator,
    StyledLogoLarge,
    LogoContainer,
    DividerIcon,
    StyledLogoSmall,
    CustomBadge,
    LockClosedIcon,
} from './styled';
import Item from './Item';
import ItemSection from './ItemSection';

const getIcon = (isAnchored: boolean) => {
    if (isAnchored) {
        return <SidebarShrinkIcon />;
    }
    return <SidebarExpandIcon />;
};

// TODO: remove this when deploy instagram integration
const RenderIfAdminOrAppReview = ({ children }: { children: ReactNode }) => {
    const { isAdmin, user } = useUserRoles();
    if (isAdmin || user?.id === '5hZvP9chZ8f4sylDwJ1l9QY80gx1' || user?.id === 'tKCgrqpqV0eZrM8Fp84OoK6wDTK2') {
        return children;
    }
    return null;
};

const SideBar = () => {
    const { agentId } = useParams<{ agentId: string }>();
    const [isExpanded, setExpanded] = useState(true);
    const [isShrinked, setShrinked] = useState(false);
    const { count } = usePendingApprovalEventsCount({
        limit: 100,
    });
    const { tasks } = useOpenTasks();
    const totalOpenedTasks = (tasks.length === 1000 ? '1K+' : tasks.length);

    const toggleShrink = useCallback(
        () => {
            setShrinked(!isShrinked);
            setExpanded(isShrinked);
        },
        [isShrinked],
    );

    const collapse = useCallback(
        () => {
            if (isShrinked) {
                setTimeout(() => setExpanded(false), 100);
            }
        },
        [isShrinked],
    );

    const expand = useCallback(
        () => {
            if (isShrinked && !isExpanded) {
                setTimeout(() => setExpanded(true), 100);
            }
        },
        [isExpanded, isShrinked],
    );

    const calendarNotifications = count === 100 ? '99+' : count;
    return (
        <Container
            isShrinked={isShrinked && !isExpanded}
            onMouseEnter={expand}
            onMouseLeave={collapse}
        >
            <Header className="rainbow-p-left_x-small rainbow-p-right_x-small">
                <LogoContainer>
                    <RenderIf isTrue={isShrinked && !isExpanded}>
                        <StyledLogoSmall />
                    </RenderIf>
                    <RenderIf isTrue={!isShrinked || isExpanded}>
                        <StyledLogoLarge />
                    </RenderIf>
                </LogoContainer>
                <RenderIf isTrue={!isShrinked || isExpanded}>
                    <ButtonIcon
                        icon={getIcon(!isShrinked)}
                        onClick={toggleShrink}
                    />
                </RenderIf>
            </Header>
            <OptionsContainer>
                <Options>
                    <Item to={`/${agentId}/tasks`} label="Tasks" icon={<TasksIcon />} customBadge={<CustomBadge color="red">New</CustomBadge>} notifications={totalOpenedTasks} isShrinked={isShrinked && !isExpanded} />
                    <Item to={`/${agentId}/inbox`} label="Inbox" icon={<InboxIcon />} customBadge={<CustomBadge color="gray">Deprecated</CustomBadge>} isShrinked={isShrinked && !isExpanded} />
                    <Item to={`/${agentId}/opportunities`} label="Opportunities" icon={<Opportunity />} isShrinked={isShrinked && !isExpanded} />
                    <Item to={`/${agentId}/calendar`} label="Calendar" icon={<CalendarIcon />} notifications={calendarNotifications ?? 0} isShrinked={isShrinked && !isExpanded} />
                    <Item to={`/${agentId}/customers`} label="Customers" icon={<User />} isShrinked={isShrinked && !isExpanded} />
                    <RenderIfAdmin>
                        <Item to={`/${agentId}/workplaces`} label="Workplaces" icon={<Building />} isShrinked={isShrinked && !isExpanded} />
                    </RenderIfAdmin>
                    <Item to={`/${agentId}/voip/history`} label="Call History" icon={<PhoneThin />} isShrinked={isShrinked && !isExpanded} />
                    <ItemSection label="Insights" prefix={`/${agentId}/insights`} icon={<Insights />} isShrinked={isShrinked && !isExpanded}>
                        <Item to={`/${agentId}/insights/calls`} label="Calls" icon={<CircleIcon />} />
                        <Item to={`/${agentId}/insights/affiliates`} label="Affiliates" icon={<CircleIcon />} />
                        <Item to={`/${agentId}/insights/opportunities`} label="Opportunities" icon={<CircleIcon />} />
                        <Item to={`/${agentId}/insights/social`} label="Social Media" icon={<CircleIcon />} />
                        <Item to={`/${agentId}/insights/website_chatbot`} label="Website Chatbot" icon={<CircleIcon />} />
                        <Item to={`/${agentId}/insights/topics`} label="Topics" icon={<CircleIcon />} />
                    </ItemSection>
                    <RenderIf isTrue={!isShrinked || isExpanded}>
                        <Separator className="rainbow-p-left_x-small rainbow-p-right_x-small">Control Panel</Separator>
                    </RenderIf>
                    <RenderIf isTrue={isShrinked && !isExpanded}>
                        <DividerIcon />
                    </RenderIf>
                    <RenderIfRole oneOf={['owner', 'editor']}>
                        <Item to={`/${agentId}/billing`} label="Billing" icon={<Dollar />} isShrinked={isShrinked && !isExpanded} />
                    </RenderIfRole>
                    <ItemSection label="Settings" prefix={`/${agentId}/settings`} icon={<SettingIcon />} isShrinked={isShrinked && !isExpanded}>
                        <Item to={`/${agentId}/settings/company-info`} label="Company Info" icon={<CircleIcon />} />
                        <Item to={`/${agentId}/settings/blocked-numbers`} label="Blocked Numbers" icon={<CircleIcon />} />
                        <Item to={`/${agentId}/settings/phone-numbers`} label="Phone Numbers" icon={<CircleIcon />} />
                        <Item to={`/${agentId}/settings/tags`} label="Tags" icon={<CircleIcon />} />
                    </ItemSection>
                    <ItemSection label="Team" prefix={`/${agentId}/team`} icon={<Users />} isShrinked={isShrinked && !isExpanded}>
                        <RenderIfRole oneOf={['owner']}>
                            <Item to={`/${agentId}/team/members`} label="Members" icon={<CircleIcon />} />
                        </RenderIfRole>
                        <Item to={`/${agentId}/team/affiliates`} label="Affiliates" icon={<CircleIcon />} />
                    </ItemSection>
                    <RenderIf isTrue={!isShrinked || isExpanded}>
                        <Separator className="rainbow-p-left_x-small rainbow-p-right_x-small">Assistant Channels</Separator>
                    </RenderIf>
                    <RenderIf isTrue={isShrinked && !isExpanded}>
                        <DividerIcon />
                    </RenderIf>
                    <ItemSection label="VoIP" prefix={`/${agentId}/voip`} icon={<PhoneThin />} isShrinked={isShrinked && !isExpanded}>
                        <RenderIfRole oneOf={['owner']}>
                            <Item to={`/${agentId}/voip/instructions`} label="Instructions" icon={<CircleIcon />} />
                        </RenderIfRole>
                        <Item to={`/${agentId}/voip/notifications`} label="Notifications" icon={<CircleIcon />} />
                    </ItemSection>
                    <RenderIfAdminOrAppReview>
                        <ItemSection label="AI Personas" prefix={`/${agentId}/responders`} icon={<Shine />} isShrinked={isShrinked && !isExpanded}>
                            <Item to={`/${agentId}/responders`} label="AI Personas v1" icon={<CircleIcon />} isShrinked={isShrinked && !isExpanded} />
                            <Item to={`/${agentId}/llms`} label="AI Personas v2" icon={<CircleIcon />} isShrinked={isShrinked && !isExpanded} />
                        </ItemSection>
                        <Item to={`/${agentId}/skills`} label="AI Skills" icon={<SkillsIcon />} />
                    </RenderIfAdminOrAppReview>
                    <RenderIfAdminOrAppReview>
                        <RenderIf isTrue={!isShrinked || isExpanded}>
                            <Separator className="rainbow-p-left_x-small rainbow-p-right_x-small">Developer</Separator>
                        </RenderIf>
                        <RenderIf isTrue={isShrinked && !isExpanded}>
                            <DividerIcon />
                        </RenderIf>
                        <Item to={`/${agentId}/developer/api`} label="API Keys" icon={<LockClosedIcon />} isShrinked={isShrinked && !isExpanded} />
                        <Item to={`/${agentId}/developer/webhooks`} label="Webhooks" icon={<Social />} isShrinked={isShrinked && !isExpanded} />
                        <Item to="https://docs.voxity.ai/" label="Documentation" icon={<Book />} isShrinked={isShrinked && !isExpanded} />
                    </RenderIfAdminOrAppReview>
                </Options>
            </OptionsContainer>
        </Container>
    );
};

export default SideBar;
