/* eslint-disable react/jsx-no-undef */
import { Field as FinalFormField } from 'react-final-form';
import PickerList from 'components/PickerList';
import { isRequired } from '@rainbow-modules/forms';
import { validateNotEmpty } from 'data/validators';
import { FieldsContainer, StyledInput, StyledTextarea } from './styled';

const entitiesList = [
    { name: 'customer', label: 'Customer', value: 'customer' },
    { name: 'opportunity', label: 'Opportunity', value: 'opportunity' },
    { name: 'workplace', label: 'Workplace', value: 'workplace' },
];

const Field = (props: any) => <FinalFormField parse={(value) => value} {...props} />;

const Fields = ({
    disabledFields = [],
}: {
    disabledFields?: string[];
}) => (
    <FieldsContainer>
        <Field
            component={StyledInput}
            name="name"
            label="Tag Name"
            labelAlignment="left"
            placeholder="Enter a name for the tag"
            borderRadius="semi-square"
            autoComplete="off"
            required
            disabled={disabledFields.includes('name')}
            validate={validateNotEmpty('This field is required')}
        />
        <Field
            component={StyledTextarea}
            name="description"
            label="Description"
            labelAlignment="left"
            placeholder="Enter a description for the tag"
            borderRadius="semi-rounded"
        />
        <Field
            component={PickerList}
            name="entity"
            label="Entiity"
            labelAlignment="left"
            placeholder="Select the entity type this tag is for"
            borderRadius="semi-rounded"
            disabled={disabledFields.includes('entity')}
            options={entitiesList}
            required={!disabledFields.includes('entity')}
            validate={isRequired('This field is required')}
        />
    </FieldsContainer>
);

export default Fields;
