import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { query, where } from 'firebase/firestore';
import { RenderIf } from 'react-rainbow-components';
import { MultiSelectOption, MultiSelectProps } from 'react-rainbow-components/components/MultiSelect';
import CirclePlusIcon from 'components/icons/plusCircle';
import CreateEditEntityTagForm from 'components/CreateEditEntityTagForm';
import useTags from 'data/firestore/agent/tag/useCollection';
import useManageEntityTags from 'data/hooks/useManageEntityTags';
import { StyledMultiSelect, Container, StyledButton } from './styled';
import { EntityTagSelectProps } from './types';
import Options from './options';

const ADD_ENTITY_TAG_FORM_PREFIX = 'entity-tag-select-add-form';

const EntityTagSelect = (props: EntityTagSelectProps) => {
    const {
        value: valueInProps,
        agentId,
        entity,
        error,
        name: nameProp,
        onChange = () => {},
        disableCreate = false,
        ...otherProps
    } = useReduxForm(props);

    const modalId = `${ADD_ENTITY_TAG_FORM_PREFIX}__${nameProp}`;

    const { create: createTag } = useManageEntityTags({
        agentId,
        modalId,
        entity,
        onCreated: (tag) => {
            const currentValue = valueInProps ?? [];
            onChange([...currentValue, tag.id]);
        },
    });

    const { data: tags = [], isLoading } = useTags(agentId, {
        listQueryFn: (ref) => query(ref, where('entity', '==', entity)),
        disabled: !agentId,
        track: [agentId],
    });

    const multiSelectValue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps && valueInProps?.length > 0) {
                return valueInProps.reduce(
                    (result: Record<string, string>[], tagName) => {
                        const found = tags.find((tag) => tag.name === tagName);
                        if (found) {
                            const { id: tagId, name } = found;
                            result.push({
                                label: name,
                                name: tagId,
                                value: name,
                            });
                        }
                        return result;
                    },
                    [],
                );
            }
        }
        return null;
    }, [tags, isLoading, valueInProps]);

    const handleCreateEntityTagClick = useCallback(
        async () => {
            if (disableCreate) return;
            createTag();
        },
        [createTag, disableCreate],
    );

    const handleOnChange = useCallback(
        (newValue: MultiSelectOption[]) => onChange(
            newValue.map((val: MultiSelectOption) => val.value as string),
        ),
        [onChange],
    );

    return (
        <>
            <Container>
                <StyledMultiSelect
                    error={error}
                    {...otherProps as MultiSelectProps}
                    enableSearch
                    isLoading={isLoading}
                    value={multiSelectValue as MultiSelectOption[]}
                    onChange={handleOnChange}
                    showCheckbox
                >
                    <Options tags={tags} />
                </StyledMultiSelect>
                <RenderIf isTrue={!disableCreate}>
                    <StyledButton
                        variant="base"
                        borderRadius="semi-square"
                        size="small"
                        hasError={Boolean(error)}
                        onClick={handleCreateEntityTagClick}
                    >
                        <CirclePlusIcon className="rainbow-m-right_x-small" />
                        New Tag
                    </StyledButton>
                </RenderIf>
            </Container>
            <CreateEditEntityTagForm modalId={modalId} />
        </>
    );
};

export default EntityTagSelect;
