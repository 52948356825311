import { PositionResolverOpts } from 'react-rainbow-components/components/InternalOverlay';

const positionResolver = (opts: PositionResolverOpts) => {
    const { trigger, content } = opts;
    return {
        top: Math.max(0, trigger.rightBottomAnchor.y + 8),
        left: Math.max(
            0,
            trigger.leftBottomAnchor.x - Math.abs((trigger.width - content.width) / 2),
        ),
    };
};

export default positionResolver;
