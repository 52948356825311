import { Field, useFormState } from 'react-final-form';
import { RenderIf } from 'react-rainbow-components';
import { validateNotEmpty } from 'data/validators';
import { FieldsContainer, Hint } from './styled';
import { FormValues } from './types';
import CallerPicker from './CallerPicker';
import CallResponderPicker from './CallResponderPicker';
import PhonePicker from './PhonePicker';

const Fields = ({ agentId }: { agentId: string }) => {
    const { values } = useFormState<FormValues>();
    return (
        <FieldsContainer>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={CallerPicker}
                name="caller"
                label="Who should make this call?"
                labelAlignment="left"
                borderRadius="semi-rounded"
            />
            <RenderIf isTrue={values?.caller === 'sara'}>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={CallResponderPicker}
                    name="callScript"
                    label="What’s the Call About?"
                    placeholder="Select the purpose"
                    formatStyle="medium"
                    labelAlignment="left"
                    borderRadius="semi-rounded"
                    validate={validateNotEmpty('This field is required')}
                    agentId={agentId}
                />
                <Hint>
                    Choose the purpose of the call so Sara AI can
                    follow the right instructions, like confirming appointments.
                </Hint>
            </RenderIf>
            <RenderIf isTrue={values?.caller !== 'sara'}>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={PhonePicker}
                    name="phoneNumber"
                    label="Select Phone Number:"
                    formatStyle="medium"
                    labelAlignment="left"
                    placeholder="Select Phone Number"
                    borderRadius="semi-rounded"
                    validate={validateNotEmpty('This field is required')}
                    agentId={agentId}
                />
                <Hint>
                    We will call this number first. When you answer, we’ll
                    connect you with the customer.
                </Hint>
            </RenderIf>
        </FieldsContainer>
    );
};

export default Fields;
