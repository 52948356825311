/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Phone from 'components/icons/phone';
import PhoneAdd from 'components/icons/phoneAdd';
import AddEditPhoneNumberModal from 'components/AddEditPhoneNumberModal';
import usePhoneNumbers from 'data/firestore/agent/phonenumber/useCollection';
import { Phonenumber } from 'data/firestore/agent/phonenumber/types';
import useManagePhoneNumbers from 'data/hooks/useManagePhoneNumbers';
import { StyledPicklist, StyledListTile } from './styled';

interface PhonePickerProps {
    id?: string;
    className?: string;
    agentId?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value: string) => void;
}

const PhonePicker = (props: PhonePickerProps) => {
    const {
        value: valueInProps,
        agentId = '',
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);
    const { create: createPhoneNumber } = useManagePhoneNumbers({
        agentId,
        onPhoneCreated: (phone: Phonenumber) => onChange(phone.number),
    });

    const { data: phoneNumbers, isLoading } = usePhoneNumbers(agentId, {
        disabled: !agentId,
        track: [agentId],
    });

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const number = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Phonenumber).number
                );
                const found = phoneNumbers.find((phoneNumber) => phoneNumber.number === number);
                if (found) {
                    return {
                        label: found.formatInternational,
                        name: found.id,
                        value: found.number,
                        searchableText: `${found.friendlyName} - ${found.number}, ${found.formatNational}, ${found.formatInternational}`,
                        icon: <Phone />,
                    };
                }
            }
        }
        return undefined;
    }, [isLoading, phoneNumbers, valueInProps]);

    const handleOnChange = useCallback(
        ({ value }: PicklistValue) => {
            if (value === 'add') {
                createPhoneNumber();
            } else {
                onChange(value);
            }
        },
        [onChange, createPhoneNumber],
    );

    const pickListOptions = useMemo(
        () => phoneNumbers.map(
            (phoneNumber) => (
                <Option
                    key={`phone-number-${phoneNumber.id}`}
                    label={(
                        <StyledListTile
                            icon={<Phone />}
                            title={phoneNumber.formatInternational}
                            subtitle={phoneNumber.friendlyName}
                        />
                    )}
                    name={phoneNumber.id}
                    value={phoneNumber.number}
                    searchableText={`${phoneNumber.friendlyName} - ${phoneNumber.number}, ${phoneNumber.formatNational}, ${phoneNumber.formatInternational}`}
                    // icon={<Phone />}
                />
            ),
        ),
        [phoneNumbers],
    );

    return (
        <>
            <StyledPicklist
                id={id}
                name={name}
                className={className}
                label={label}
                labelAlignment="left"
                tabIndex={tabIndex}
                required={required}
                placeholder={placeholder}
                isLoading={isLoading}
                value={pickListvalue}
                onChange={handleOnChange}
                error={error}
            >
                <Option name="add" label="Add new phone number" value="add" icon={<PhoneAdd />} />
                {pickListOptions}
            </StyledPicklist>
            <AddEditPhoneNumberModal />
        </>
    );
};

export default PhonePicker;
