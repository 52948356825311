import { StyledCallTypeBadge } from './styled';

interface CallTypeBadgeProps {
    value?: string;
}

const labelMap: Record<string, string> = {
    inbound: 'Inbound',
    outbound: 'Outbound',
    'outbound-api': 'Outbound',
};

const CallTypeBadge = ({ value }: CallTypeBadgeProps) => (
    <StyledCallTypeBadge
        label={labelMap[value || 'unknown']}
        borderRadius="semi-rounded"
    />
);

export default CallTypeBadge;
