import Footer from 'components/Footer';
import AgentPhoneNumbers from './AgentPhoneNumbers';
import { PageContainer, Container } from './styled';
import OutboundCallPhoneNumbers from './OutboundCallPhoneNumbers';

const PhoneNumbersPage = () => (
    <PageContainer>
        <Container>
            <AgentPhoneNumbers />
            <OutboundCallPhoneNumbers />
        </Container>
        <Footer />
    </PageContainer>
);

export default PhoneNumbersPage;
