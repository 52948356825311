/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';
import useWorkplaces from 'data/firestore/agent/workplace/useCollection';
import { Workplace } from 'data/firestore/agent/workplace/types';
import Options from './options';

interface WorkplacePickerProps {
    id?: string;
    className?: string;
    agentId?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value: string) => void;
}

const WorkplacePicker = (props: WorkplacePickerProps) => {
    const {
        value: valueInProps,
        agentId = '',
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const { data: workplaces = [], isLoading } = useWorkplaces(
        agentId,
        {
            disabled: !agentId,
            track: [agentId],
        },
    );

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Workplace).id
                );
                const found = workplaces.find((workplace) => workplace.id === valueId);
                if (found) {
                    return {
                        label: found.name,
                        name: found.id,
                        value: found.id,
                        searchableText: found.name,
                    };
                }
            }
        }
        return undefined;
    }, [isLoading, workplaces, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={isLoading}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
        >
            <Option label="Select workplace" variant="header" />
            <Options workplaces={workplaces} />
        </Picklist>
    );
};

export default WorkplacePicker;
