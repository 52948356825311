import { EntityGet } from 'data/firestore/types';
import { Tag } from 'data/firestore/agent/tag/types';
import { CellWrapper, StyledBadge } from '../styled';

interface Props {
    row?: EntityGet<Tag>;
}

const EntityColumn = ({ row }: Props) => (
    <CellWrapper>
        <StyledBadge label={row?.entity} />
    </CellWrapper>
);

export default EntityColumn;
