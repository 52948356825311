import { CellWrapper } from '../../columns/styled';

interface Props {
    value?: string;
}

const FriendlyName = ({ value }: Props) => (
    <CellWrapper>
        {value}
    </CellWrapper>
);

export default FriendlyName;
