import { Button, Column } from 'react-rainbow-components';
import Table from 'components/Table';
import Plus from 'components/icons/plus';
import AddEditWebsiteChatbotModal from 'components/AddEditWebsiteChatbotModal';
import useManageWebsiteChatbots from 'data/hooks/useManageWebsiteChatbots';
import { FeaturesListProps } from '../types';
import {
    Header,
    Title,
    SubTitle,
    HeaderContainer,
} from '../styled';
import UrlColumn from '../columns/url';
import FeatureColumn from '../columns/feature';
import AIPersonaColumn from '../columns/aiPersona';
import WidgetChatbotActionsColumn from './columns/actions';

const WidgetChatbots = ({
    agentId,
    group,
    features = [],
    isLoading,
}: FeaturesListProps) => {
    const { create } = useManageWebsiteChatbots({ agentId });
    return (
        <>
            <HeaderContainer>
                <Header>
                    <Title>{group?.label}</Title>
                    <SubTitle>{group?.description}</SubTitle>
                </Header>
                <Button
                    variant="brand"
                    size="medium"
                    borderRadius="semi-rounded"
                    onClick={() => create()}
                >
                    <Plus className="rainbow-m-right_small" />
                    New Website Chatbot
                </Button>
            </HeaderContainer>
            <Table isLoading={isLoading} keyField="name" data={features}>
                <Column
                    header="Name"
                    field="label"
                    component={FeatureColumn}
                />
                <Column
                    header="Endpoint URL"
                    field="url"
                    component={UrlColumn}
                />
                <Column
                    header="AI Persona"
                    agentId={agentId}
                    component={AIPersonaColumn}
                    width={280}
                />
                <Column
                    agentId={agentId}
                    component={WidgetChatbotActionsColumn}
                    width={100}
                />
            </Table>
            <AddEditWebsiteChatbotModal />
        </>
    );
};

export default WidgetChatbots;
