import { useCallback } from 'react';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import useWorkplace from 'data/firestore/agent/workplace/useOnce';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { formatPhoneNumber } from 'data/services/phone/formatter';
import { Workplace } from 'data/firestore/agent/workplace/types';
import { BaseFormProps } from './types';
import FormFooter from './footer';
import Form from './form';
import { Container, Content } from './styled';

const mapDataToFormValues = (
    workplaceInfo: Record<string, unknown>,
) => {
    const {
        address,
        phoneNumber,
        socialLinks = {},
        ...rest
    } = workplaceInfo;

    const typedAddress = address as Workplace['address'];
    return {
        ...rest,
        phoneNumber: formatPhoneNumber(phoneNumber as string),
        address: typedAddress?.formattedAddress,
        ...Object.keys(socialLinks as Record<string, string>).reduce((acc, key:string) => {
            const value = (socialLinks as Record<string, string>)[key];
            return {
                ...acc,
                [key]: value,
            };
        }, {}),
    } as Record<string, unknown>;
};

interface EditFormProps extends BaseFormProps {}

const EditForm = ({
    onRequestClose = () => {},
    onCompleted = () => {},
    agentId = '',
    ...workplaceInfo
}: EditFormProps) => {
    const { data, isLoading } = useWorkplace(
        agentId as string,
        workplaceInfo?.id as string,
        { disabled: !(agentId || workplaceInfo?.id) },
    );

    const workplaceData = (agentId ? data : workplaceInfo);

    const {
        mutateAsync: updateWorkplace,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/agents/${agentId}/workplaces/${workplaceInfo?.id}`,
        method: 'PATCH',
        onSuccess: (response) => {
            onRequestClose();
            onCompleted(response as unknown as Record<string, unknown>);
        },
    });

    const handleSubmit = useCallback(async (values: Record<string, unknown>) => {
        try {
            showAppSpinner();
            await updateWorkplace({
                body: values,
            });
        } catch (error) {
            // no catch
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [updateWorkplace]);

    return (
        <Container>
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Spinner size="large" variant="brand" type="arc" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <Form
                        agentId={agentId}
                        id="workplace-form"
                        initialValues={mapDataToFormValues(
                            workplaceData as Record<string, unknown> || {},
                        )}
                        onSubmit={handleSubmit}
                    />
                </RenderIf>
            </Content>
            <FormFooter
                formId="workplace-form"
                submitDisabled={isLoading}
                submitButtonLabel="Update"
                onCancelClick={onRequestClose}
            />
        </Container>
    );
};

export default EditForm;
