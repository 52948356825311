import { Field } from '@rainbow-modules/forms';
import PhoneInput from 'components/PhoneInput';
import validatePhoneNumber from 'data/services/phone/isValid';
import { FieldsContainer, StyledInput } from './styled';

const validatePhone = (value: Record<string, string> = {}) => {
    if (!validatePhoneNumber(`${value?.countryCode}${value?.phone}`)) return 'Please enter a valid phone number.';
    return undefined;
};

interface FieldsProps {
    disabledFields?: string[],
}

const Fields = ({ disabledFields = [] }: FieldsProps) => (
    <FieldsContainer>
        <Field
            name="friendlyName"
            component={StyledInput}
            label="Friendly Name"
            placeholder="Enter a friendly name"
            labelAlignment="left"
            borderRadius="semi-square"
        />
        <Field
            name="phone"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={PhoneInput}
            disabled={disabledFields.includes('phone')}
            countries={['us']}
            label="Phone Number"
            required
            placeholder="Enter phone number"
            borderRadius="semi-square"
            labelAlignment="left"
            validate={validatePhone}
        />
    </FieldsContainer>
);

export default Fields;
