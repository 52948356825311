import styled from 'styled-components';
import { GREY_400 } from '../../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-weight: 300;
`;

export const ActionsCellWrapper = styled(CellWrapper)`
    justify-content: flex-end;
    padding: 0 2rem 0 0;
`;
