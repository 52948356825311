/* eslint-disable react/jsx-no-undef */
import { Field as FinalFormField } from 'react-final-form';
import { isEmpty } from '@rainbow-modules/validation';
import InstagramIcon from 'components/icons/instagram';
import Facebook from 'components/icons/facebook';
import LinkedInIcon from 'components/icons/linkedin';
import PhoneInput from 'components/PhoneInput';
import isValidPhoneNumber from 'data/services/phone/isValid';
import { validateNotEmpty } from 'data/validators';
import {
    FieldsSection,
    FieldSectionTitle,
    StyledInput,
    StyledGoogleAddressLookup,
    StyledTextarea,
    StyledEntityTagSelect,
} from './styled';

const validatePhoneNumber = (
    value?: { countryCode: string, isoCode: string, phone: string },
) => {
    if (isEmpty(value?.phone)) {
        return undefined;
    }
    if (!isValidPhoneNumber(`${value?.countryCode}${value?.phone}`)) {
        return 'Invalid phone number';
    }
    return undefined;
};

const Field = (props: any) => <FinalFormField parse={(value) => value} {...props} />;

const Fields = ({
    agentId,
}: {
    agentId: string;
}) => (
    <>
        <FieldsSection>
            <FieldSectionTitle>General Information</FieldSectionTitle>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="name"
                label="Workplace Name"
                labelAlignment="left"
                placeholder="Acme Corp"
                borderRadius="semi-rounded"
                autoComplete="off"
                validate={validateNotEmpty('Field is required')}
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="website"
                label="Website URL"
                type="website"
                labelAlignment="left"
                placeholder="Website URL"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={PhoneInput}
                name="phoneNumber"
                label="Phone Number"
                labelAlignment="left"
                placeholder=""
                borderRadius="semi-rounded"
                validate={validatePhoneNumber}
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="email"
                label="Email"
                type="email"
                labelAlignment="left"
                placeholder="johndoe@gmail.com"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledGoogleAddressLookup}
                name="address"
                label="Address"
                labelAlignment="left"
                placeholder="45 Roker Terrace"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
        </FieldsSection>
        <FieldsSection>
            <FieldSectionTitle>Social Media Profile</FieldSectionTitle>
            <Field
                component={StyledInput}
                name="linkedin"
                icon={<LinkedInIcon />}
                label="LinkedIn"
                labelAlignment="left"
                placeholder="LinkedIn profile url"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                component={StyledInput}
                name="facebook"
                icon={<Facebook />}
                label="Facebook"
                labelAlignment="left"
                placeholder="Facebook profile url"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                component={StyledInput}
                name="instagram"
                icon={<InstagramIcon />}
                label="Instagram"
                labelAlignment="left"
                placeholder="Instagram profile url"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
        </FieldsSection>
        <FieldsSection>
            <FieldSectionTitle>Additional Information</FieldSectionTitle>
            <Field
                component={StyledTextarea}
                name="description"
                label="Workplace Description"
                labelAlignment="left"
                placeholder="Enter description"
                borderRadius="semi-rounded"
            />
            <Field
                component={StyledEntityTagSelect}
                name="tags"
                label="Tags"
                labelAlignment="left"
                placeholder="Select tags"
                borderRadius="semi-rounded"
                agentId={agentId}
                entity="workplace"
            />
        </FieldsSection>
    </>
);

export default Fields;
