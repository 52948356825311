import styled, { css } from 'styled-components';
import { BadgeOverlay } from 'react-rainbow-components';
import { Tag } from '@rainbow-modules/icons';
import Input from 'components/Input';
import EntityTagsFilter from 'components/EntityTagsFilter';
import {
    GREY_200,
    GREY_100,
    GREY_300,
    PURPLE_100,
    PURPLE_500,
} from '../../../constants';

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1.5rem;
`;

export const SearchInput = styled(Input)`
    flex: 1;
    min-width: 14rem;
    margin-top: 4px;
    
    input[disabled] {
        pointer-events: none;
        background: transparent;
        border: none;
        
        ::placeholder {
            color: ${GREY_200} !important;
        }
    }

    ${(props) => props.disabled && css`
        span {
            color: ${GREY_100} !important;
        }
    `};
`;

export const RightContent = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const Container = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const StyledBadgeOverlay = styled(BadgeOverlay)`
    ${(props) => props.variant === 'brand' && css`
        & > span {
            background: ${PURPLE_100};
            color: ${PURPLE_500};
        }
    `};
`;

export const StyledEntityTagsFilter = styled(EntityTagsFilter)`
    padding-left: 2.5rem;
`;

export const TagIcon = styled(Tag)`
    width: 1.25rem !important;
    height: 1.25rem !important;
`;
