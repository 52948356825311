import { useMemo } from 'react';
import ListTile from 'components/ListTile';
import { EntityGet } from 'data/firestore/types';
import { Workplace } from 'data/firestore/agent/workplace/types';
import { UserCellWrapper, StyledLocationIcon } from './styled';

interface Props {
    row?: EntityGet<Workplace>;
    disableLink?: boolean;
}

const WorkplaceColumn = ({ row, disableLink = false }: Props) => {
    const {
        id = '', name = '', address,
    } = row || {};

    const LinkTo = useMemo(() => (disableLink ? undefined : `${id}/customers`), [disableLink, id]);

    return (
        <UserCellWrapper>
            <ListTile
                icon={<StyledLocationIcon />}
                to={LinkTo}
                title={name}
                subtitle={address?.formattedAddress}
            />
        </UserCellWrapper>
    );
};

export default WorkplaceColumn;
