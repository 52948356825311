import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { RenderIf } from 'react-rainbow-components';
import Button from 'components/Button';
import Calendar from 'components/icons/calendarThin';
import ButtonIcon from 'components/ButtonIcon';
import Close from 'components/icons/close';
import Gradient from 'components/Gradient';
import useSearchFilters from 'hooks/useSearchFilters';
import Accordion from '../../../Opportunities/View/accordion';
import {
    FilterHeader,
    Title,
    StyledDatePickerFilter,
    FiltersContainer,
    FiltersContent,
} from '../../../Opportunities/View/styled';
import { StyledEntityTagsFilter, TagIcon } from '../styled';

interface Params {
    agentId: string;
    onRequestClose?: () => void;
}

const filterNames = ['createdAt', 'tags'];

const Filters = ({
    agentId,
    onRequestClose = () => {},
}: Params) => {
    const {
        values,
        getValue: getFilterValue,
        setValue: setFilterValue,
        clear: clearFilters,
    } = useSearchFilters({ filterNames });

    const activeFiltersCount = useMemo(
        () => filterNames.reduce(
            (count, key) => (
                getFilterValue(key) !== null
                    ? count + 1
                    : count
            ),
            0,
        ),
        [getFilterValue],
    );

    const createdAtFilterValue = useMemo(
        () => {
            const { createdAt } = values;
            if (!createdAt) return undefined;
            return String(createdAt).split(',').map((strDate) => DateTime.fromISO(strDate).toJSDate());
        },
        [values],
    );

    const handleChangeCreatedAt = useCallback(
        (newFilterValue: Date[]) => setFilterValue(
            'createdAt',
            (
                newFilterValue.length === 0
                    ? null
                    : newFilterValue.map((date) => date.toISOString()).join(',')
            ),
        ),
        [setFilterValue],
    );

    const handleChangeTags = useCallback(
        (newFilterValue: string[] | null) => setFilterValue(
            'tags',
            (
                newFilterValue === null
                    ? null
                    : newFilterValue.join(',')
            ),
        ),
        [setFilterValue],
    );

    const tagsFilterValue = useMemo(
        () => {
            const { tags } = values;
            if (tags !== null) return String(tags).split(',');
            return null;
        },
        [values],
    );
    return (
        <FiltersContainer>
            <FilterHeader>
                <Title>
                    Filters
                    {' '}
                    <RenderIf isTrue={activeFiltersCount > 0}>
                        (
                        {activeFiltersCount}
                        )
                    </RenderIf>
                </Title>
                <div>
                    <Button
                        label="Clear"
                        variant="base"
                        size="small"
                        disabled={!activeFiltersCount}
                        borderRadius="semi-square"
                        onClick={clearFilters}
                    />
                    <ButtonIcon
                        borderRadius="semi-square"
                        size="small"
                        icon={<Close />}
                        onClick={onRequestClose}
                    />
                </div>
            </FilterHeader>
            <FiltersContent>
                <Accordion icon={<Calendar />} label="Created Date" name="createdAt">
                    <StyledDatePickerFilter
                        onChange={handleChangeCreatedAt}
                        value={createdAtFilterValue}
                    />
                </Accordion>
                <Accordion icon={<TagIcon />} label="Tags" name="tags">
                    <StyledEntityTagsFilter
                        agentId={agentId}
                        value={tagsFilterValue}
                        onChange={handleChangeTags}
                        entity="workplace"
                    />
                </Accordion>
            </FiltersContent>
            <Gradient position="bottom" />
        </FiltersContainer>
    );
};

export default Filters;
