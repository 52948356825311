import { Container, Tag } from './styled';

interface TagsListProps {
    tags?: string[];
    align?: 'left' | 'center' | 'right';
}

const TagsList = ({ tags = [], align = 'center' }: TagsListProps) => {
    if (tags.length === 0) return null;
    return (
        <Container align={align}>
            {tags.map((tag) => <Tag label={tag} size="small" borderRadius="semi-square" />)}
        </Container>
    );
};

export default TagsList;
