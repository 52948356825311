import { Outlet, useParams } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import CreateEditWorkplaceForm from 'components/CreateEditWorkplaceForm';
import BasicPageLayout from 'layouts/BasicPage';
import { ADD_EDIT_WORKPLACE_MODAL } from '../../constants';
import { StyledDrawer } from './styled';

type FormOpenMode = 'edit' | 'create';

const WorkplacesPage = () => {
    const { agentId = '' } = useParams();
    const connectedAddModifyProps = useConnectModal(
        ADD_EDIT_WORKPLACE_MODAL,
        { size: 'small', slideFrom: 'right' },
    );

    const [, closeDrawer] = useOpenModal(ADD_EDIT_WORKPLACE_MODAL);
    const {
        mode,
        onCompleted,
        workplaceData = {},
        ...drawerProps
    } = connectedAddModifyProps;

    return (
        <BasicPageLayout>
            <Outlet />
            <StyledDrawer
                {...drawerProps}
                onRequestClose={closeDrawer}
            >
                <CreateEditWorkplaceForm
                    mode={mode as FormOpenMode}
                    agentId={agentId}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onCompleted={onCompleted}
                    onRequestClose={closeDrawer}
                    {...(workplaceData as object)}
                />
            </StyledDrawer>
        </BasicPageLayout>
    );
};

export default WorkplacesPage;
