import styled, { css } from 'styled-components';
import { Tag as TagIcon } from '@rainbow-modules/icons';
import Badge from 'components/Badge';
import Input from 'components/Input';
import Card from '../../components/Card';
import {
    GREY_400, GREY_300, PURPLE_500, PURPLE_100, GREEN_100, GREEN_600, TEAL_100, TEAL_600,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledCard = styled(Card)`
    height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
`;

export const SearchInput = styled(Input)`
    flex: 1;
`;

export const TableContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-weight: 300;
`;

export const ActionsCellWrapper = styled(CellWrapper)`
    justify-content: flex-end;
    padding: 0 2rem 0 0;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const ActionsColumnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 0.5rem;
    padding-right: 1rem;
`;

export const StyledBadge = styled(Badge)`
    padding: 0.125rem 0.375rem 0.125rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.875rem;
    whites-pace: nowrap;
    
    ${(props) => props.label === 'workplace' && css`
        color: ${TEAL_600};
        background-color: ${TEAL_100};
    `}
    
    ${(props) => props.label === 'opportunity' && css`
        color: ${GREEN_600};
        background-color: ${GREEN_100};
    `}

    ${(props) => props.label === 'customer' && css`
        color: ${PURPLE_500};
        background-color: ${PURPLE_100};
    `}
`;

export const TagCellWrapper = styled(CellWrapper)`
    padding: 10px 10px 10px 20px;
`;

export const StyledTagIcon = styled(TagIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;
