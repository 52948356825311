import styled from 'styled-components';
import { Chip } from 'react-rainbow-components';

export const Container = styled.div<{ align: string }>`
    display: flex;
    justify-content: ${(props) => props.align};
    align-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

export const Tag = styled(Chip)`
    font-size: 0.625rem;
    height: 1.125rem;
    
    & > span {
        text-transform: none; 
    }
`;
