import { Link } from 'react-router-dom';
import { Open } from '@rainbow-modules/icons';
import Location from 'components/icons/location';
import useWorkplace from 'data/firestore/agent/workplace/use';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import Phone from 'components/icons/phone';
import Facebook from 'components/icons/facebookSolid';
import InstagramIcon from 'components/icons/instagramSolid';
import Email from 'components/icons/email';
import LinkedInIcon from 'components/icons/linkedin';
import { RenderIf } from 'react-rainbow-components';
import { Workplace } from 'data/firestore/agent/workplace/types';
import DetailElement from './detail';
import {
    Container,
    DetailsElements,
    SectionTitle,
    Footer,
    Gradient,
} from './styled';
import WorkplaceInfo from './info';

const getMapsUrl = (address?: Workplace['address']) => {
    if (!address) return '';
    const { place_id: placeId } = address.addressInfo as Record<string, unknown>;
    return `https://www.google.com/maps/place/?q=place_id:${placeId}`;
};

interface WorkplaceProfileProps {
    agentId?: string;
    workplaceId?: string;
    showAdditionalInfo?: boolean;
    footer?: React.ReactNode;
}

const WorkplaceProfile = ({
    agentId = '',
    workplaceId = '',
    showAdditionalInfo = false,
    footer,
}: WorkplaceProfileProps) => {
    const { data: workplaceData, isLoading } = useWorkplace(
        agentId,
        workplaceId,
        {
            disabled: !agentId || !workplaceId,
        },
    );

    const {
        facebook: facebookProfileURL,
        instagram: instagramProfileURL,
        linkedin: linkedinProfileURL,
    } = (workplaceData?.socialLinks as Record<string, string>) || {};

    return (
        <Container>
            <WorkplaceInfo
                workplace={workplaceData}
            />
            <div className="rainbow-flex rainbow-flex_column">
                <DetailsElements>
                    <DetailElement
                        icon={<Phone />}
                        label="Website URL"
                        value={workplaceData?.website}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Phone />}
                        label="Phone Number"
                        value={formatIntlPhoneNumber(workplaceData?.phoneNumber)}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Email />}
                        label="Email"
                        value={workplaceData?.email}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Location />}
                        label="Address"
                        value={(
                            <div className="rainbow-flex">
                                <span className="rainbow-p-right_xx-small">{workplaceData?.address?.formattedAddress}</span>
                                <Link to={getMapsUrl(workplaceData?.address)} target="_blank">
                                    <Open />
                                </Link>
                            </div>
                        )}
                        isLoading={isLoading}
                    />
                </DetailsElements>
            </div>
            <RenderIf isTrue={showAdditionalInfo}>
                <div className="rainbow-flex rainbow-flex_column">
                    <SectionTitle>ADDITIONAL INFORMATION</SectionTitle>
                    <DetailsElements>
                        <DetailElement
                            icon={<LinkedInIcon />}
                            label="LinkedIn Profile URL"
                            to={linkedinProfileURL}
                            value={linkedinProfileURL}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<InstagramIcon />}
                            label="Instagram Profile URL"
                            to={instagramProfileURL}
                            value={instagramProfileURL}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<Facebook />}
                            label="Facebook Profile URL"
                            to={facebookProfileURL}
                            value={facebookProfileURL}
                            isLoading={isLoading}
                        />
                    </DetailsElements>
                </div>
            </RenderIf>
            <Gradient />
            <Footer>
                {footer}
            </Footer>
        </Container>
    );
};

export default WorkplaceProfile;
