import styled from 'styled-components';
import ListTile from 'components/ListTile';
import { StyledPicklist as Picklist } from '../styled';

export const StyledPicklist = styled(Picklist)`
    width: 100%;
`;

export const StyledListTile = styled(ListTile)`
    width: 100%;
    & > div:last-child {
        line-height: unset;

        & > span:last-child {
            font-size: 0.75rem;
            padding-left: 0.55rem;
        }
    }
`;
