import { Option } from 'react-rainbow-components';
import { Workplace } from 'data/firestore/agent/workplace/types';

const Options = ({ workplaces = [] }: { workplaces: Workplace[] }) => workplaces.map(
    (workplace) => ((
        <Option
            key={`workplace-option__${workplace.id}`}
            label={workplace.name}
            name={workplace.id}
            value={workplace.id}
            searchableText={workplace.name}
        />
    )),
);

export default Options;
