import styled from 'styled-components';
import Card from 'components/Card';
import {
    GREY_200, GREY_300, GREY_500, GREY_800,
} from '../../constants';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 120px);
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.625rem;
    align-self: stretch;
    flex-grow: 1;
    width: 100%;
`;

export const StyledCard = styled(Card)`
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    gap: 1.625rem;
`;

export const TableContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const EmptyLabel = styled.span`
    font-style: italic;
    color: ${GREY_300};
    font-weight: 300;
    font-size: 0.9375rem;
`;

export const Message = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_200};
    text-align: center;
    padding-top: 0.75rem;
`;

export const Title = styled.h3`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    color: ${GREY_800};
`;

export const Description = styled.p`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1rem;
    color: ${GREY_500};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0.50rem;
`;
