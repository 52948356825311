import { useCallback } from 'react';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import { DateTime } from 'luxon';
import useCustomer from 'data/firestore/agent/customer/useOnce';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { formatPhoneNumber } from 'data/services/phone/formatter';
import { BaseFormProps } from './types';
import FormFooter from './footer';
import Form from './form';
import { Container, Content } from './styled';

const mapDataToFormValues = (
    customerInfo: Record<string, unknown>,
) => {
    const {
        address,
        phoneNumber,
        workplaceId,
        dob,
        ...rest
    } = customerInfo;

    return {
        ...rest,
        workplace: workplaceId,
        dob: dob && DateTime.fromISO((dob as Date).toISOString().slice(0, 10)).toJSDate(),
        phoneNumber: formatPhoneNumber(phoneNumber as string),
        address: (address as Record<string, unknown>)?.addressInfo,
    } as Record<string, unknown>;
};

interface EditFormProps extends BaseFormProps {}

const EditForm = ({
    onRequestClose = () => {},
    onCompleted = () => {},
    agentId = '',
    ...customerInfo
}: EditFormProps) => {
    const { data, isLoading } = useCustomer(
        agentId as string,
        customerInfo?.id as string,
        { disabled: !(agentId || customerInfo?.id) },
    );

    const customerData = (agentId ? data : customerInfo);

    const {
        mutateAsync: updateCustomer,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/customers/${customerInfo?.id}`,
        method: 'PATCH',
        onSuccess: (response) => {
            onRequestClose();
            onCompleted(response as unknown as Record<string, unknown>);
        },
    });

    const handleSubmit = useCallback(async (values: Record<string, unknown>) => {
        try {
            showAppSpinner();
            await updateCustomer({
                body: {
                    agentId,
                    ...values,
                },
            });
        } catch (error) {
            // no catch
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [agentId, updateCustomer]);

    const disabledFields = !customerData?.channel || customerData?.channel === 'voip' ? ['phoneNumber'] : [];

    return (
        <Container>
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Spinner size="large" variant="brand" type="arc" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <Form
                        agentId={agentId}
                        disabledFields={disabledFields}
                        id="customer-form"
                        initialValues={mapDataToFormValues(
                            customerData as Record<string, unknown> || {},
                        )}
                        onSubmit={handleSubmit}
                    />
                </RenderIf>
            </Content>
            <FormFooter
                formId="customer-form"
                submitDisabled={isLoading}
                submitButtonLabel="Update"
                onCancelClick={onRequestClose}
            />
        </Container>
    );
};

export default EditForm;
