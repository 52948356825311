import { useConnectModal } from '@rainbow-modules/hooks';
import FormModal from 'components/FormModal';
import tryParseNumber from 'data/services/string/parseNumber';
import { CONFIG_OPPORTUNITY_PIPELINE_STAGE_TRACKING_MODAL } from '../../constants';
import Fields from './fields';

const mapFormValues = (values: Record<string, unknown>) => {
    const {
        enableTracking,
        inactiveDaysThreshold,
    } = values;

    return {
        enableTracking,
        inactiveDaysThreshold: (
            enableTracking
                ? tryParseNumber(inactiveDaysThreshold as string)
                : undefined
        ),
    };
};

const ConfigOpportunityStageTrackingForm = () => {
    const {
        onSubmit, ...connectedModalProps
    } = useConnectModal(CONFIG_OPPORTUNITY_PIPELINE_STAGE_TRACKING_MODAL);

    const onSubmitMiddleware = (formValues: Record<string, unknown>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (onSubmit) onSubmit(mapFormValues(formValues));
    };

    return (
        <FormModal
            {...connectedModalProps}
            borderRadius="semi-square"
            size="small"
            fields={Fields}
            onSubmit={onSubmitMiddleware}
        />
    );
};

export default ConfigOpportunityStageTrackingForm;
