import { useRef, useState } from 'react';
import { PositionResolverOpts } from 'react-rainbow-components/components/InternalOverlay';
import ButtonIcon from 'components/ButtonIcon';
import Filter from 'components/icons/filter';
import { InternalOverlay } from 'react-rainbow-components';
import { FilterButtonDropDown } from '../../../Opportunities/View/styled';
import Filters from './filters';

const positionResolver = (opts: PositionResolverOpts) => {
    const { trigger, content, viewport } = opts;

    const desiredPos = {
        top: Math.max(0, trigger.leftUpAnchor.y + trigger.height + 5),
        left: Math.max(0, viewport.width - content.width - 14),
        bottom: 14,
    };

    return desiredPos;
};

interface FilterButtonProps {
    label?: string;
    agentId:string;
}

const FilterButton = ({
    label,
    agentId,
}: FilterButtonProps) => {
    const triggerRef = useRef<any>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ButtonIcon
                ref={triggerRef}
                onClick={() => setIsOpen(!isOpen)}
                icon={<Filter />}
                title={label}
                borderRadius="semi-rounded"
                variant="base"
                className="rainbow-m-right_small"
                tooltip="Filter"
            />
            <InternalOverlay
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                positionResolver={positionResolver}
                isVisible={isOpen}
                triggerElementRef={() => triggerRef.current.buttonRef}
            >
                <FilterButtonDropDown ref={dropdownRef}>
                    <Filters
                        agentId={agentId}
                        onRequestClose={() => setIsOpen(false)}
                    />
                </FilterButtonDropDown>
            </InternalOverlay>
        </>
    );
};

export default FilterButton;
