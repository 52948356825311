/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { or, query, where } from 'firebase/firestore';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import useResponders from 'data/firestore/agent/llm/useCollection';
import { Llm as LLmResponder } from 'data/firestore/agent/llm/types';
import Script from 'components/icons/script';
import { StyledPicklist } from './styled';

interface CallResponderPickerProps {
    id?: string;
    className?: string;
    agentId?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value: string) => void;
}

const CallResponderPicker = (props: CallResponderPickerProps) => {
    const {
        value: valueInProps,
        agentId = '',
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const { data: responders, isLoading } = useResponders(agentId, {
        listQueryFn: (ref) => query(ref, or(
            where('featureSchema', '==', 'system.call.incoming'),
            where('featureSchema', '==', 'system.call.outbound'),
        )),
        disabled: !agentId,
        track: [agentId],
    });

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as LLmResponder).id
                );
                const found = responders.find((responder) => responder.id === valueId);
                if (found) {
                    return {
                        label: found.name,
                        name: found.id,
                        value: found.id,
                        searchableText: found.name,
                        icon: <Script />,
                    };
                }
            }
        }
        return undefined;
    }, [isLoading, responders, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => responders.map(
            (responder) => (
                <Option
                    key={`responder_${responder.id}`}
                    label={responder.name}
                    name={responder.id}
                    value={responder.id}
                    searchableText={responder.name}
                    icon={<Script />}
                />
            ),
        ),
        [responders],
    );

    return (
        <StyledPicklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={isLoading}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
        >
            {pickListOptions}
        </StyledPicklist>
    );
};

export default CallResponderPicker;
