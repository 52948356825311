import styled from 'styled-components';
import { GREY_200 } from '../../../constants';

export const FieldsContainer = styled.div`
    display: flex;
    justify-items: stretch;
    align-content: center;
    gap: 1rem;
    padding: 1.5rem 0 1rem;


    & > div {
        flex: 1;
    }
`;

export const Message = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_200};
    text-align: center;
    padding-top: 0.75rem;
`;
