import { useCallback, useMemo } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import ModalTitle from 'components/ModalTitle';
import { Widgetchatbot } from 'data/firestore/agent/widgetchatbot/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { ADD_EDIT_WIDGET_CHATBOT_MODAL } from '../../constants';

const mapDataToFormValues = (data: Widgetchatbot) => ({
    name: data.name,
});

const mapFormValuesToData = (values: Record<string, unknown>) => {
    const { name } = values;
    return {
        name,
    };
};

const useManageWebsiteChatbots = ({
    agentId,
    onCreated = () => {},
    onDeleted = () => {},
} : {
    agentId: string;
    onCreated?: (phone: Widgetchatbot) => void
    onDeleted?: () => void;
}) => {
    const [openForm, closeForm] = useOpenModal(ADD_EDIT_WIDGET_CHATBOT_MODAL);

    const { mutateAsync: createWebsiteChatbot } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/website-chatbots/`,
        onSuccess: closeForm,
    });

    const { mutateAsync: updateWebsiteChatbot } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closeForm,
    });

    const { mutateAsync: deleteWebsiteChatbot } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreateWebsiteChatbot = useCallback(
        (initialValues: Record<string, unknown> = {}) => openForm({
            title: 'Create New Website Chatbot',
            subtitle: 'Create Chatbots for your websites.',
            submitButtonLabel: 'Create',
            initialValues: {
                type: 'open',
                ...initialValues,
            },
            agentId,
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    const phone = await createWebsiteChatbot({
                        body: mapFormValuesToData(values),
                    }) as Widgetchatbot;
                    showAppNotification({
                        title: 'Success',
                        description: 'Chatbot added successfuly.',
                        icon: 'success',
                        timeout: 5000,
                    });
                    onCreated(phone);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, createWebsiteChatbot, onCreated, openForm],
    );

    const handleEditWebsiteChatbot = useCallback(
        (data: Widgetchatbot) => openForm({
            id: data?.id,
            agentId,
            disabledFields: [],
            title: <ModalTitle>Edit Website Chatbot</ModalTitle>,
            subtitle: 'Create Chatbots for your websites.',
            submitButtonLabel: 'Update',
            initialValues: mapDataToFormValues(data),
            onSubmit: async (values: Record<string, unknown>) => {
                const { name } = values;
                showAppSpinner();
                try {
                    await updateWebsiteChatbot({
                        pathname: `/agents/${agentId}/website-chatbots/${data.id}`,
                        body: {
                            name,
                        },
                    });
                    showAppNotification({
                        title: 'Success',
                        description: 'Your changes have been saved.',
                        icon: 'success',
                        timeout: 5000,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [openForm, agentId, updateWebsiteChatbot],
    );

    const handleDeleteWebsiteChatbot = useCallback(
        async (chatbotId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title="Remove website chatbot?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteWebsiteChatbot({
                    pathname: `/agents/${agentId}/website-chatbots/${chatbotId}`,
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Website chatbot deleted successfuly.',
                    icon: 'success',
                    timeout: 5000,
                });
                onDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deleteWebsiteChatbot, agentId, onDeleted],
    );

    return useMemo(() => ({
        create: handleCreateWebsiteChatbot,
        edit: handleEditWebsiteChatbot,
        remove: handleDeleteWebsiteChatbot,
        closeForm,
    }), [
        closeForm,
        handleCreateWebsiteChatbot,
        handleDeleteWebsiteChatbot,
        handleEditWebsiteChatbot,
    ]);
};

export default useManageWebsiteChatbots;
