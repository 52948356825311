import { useContext } from 'react';
import { BarDatum, BarTooltipProps } from '@nivo/bar';
import { TooltipContainer } from './styled';
import context, { Context } from '../context';

const BarTooltip = ({ value, id }: BarTooltipProps<BarDatum>) => {
    const { chatbots } = useContext<Context>(context);
    return (
        <TooltipContainer>
            <h2>AI-sent messages on website chatbot:</h2>
            <div>
                <span>{`${chatbots[id] || id}:`}</span>
                <b>{value}</b>
            </div>
            <p>Click to see details</p>
        </TooltipContainer>
    );
};

export default BarTooltip;
